import { observer } from 'mobx-react'
import { useEffect, useState } from 'react'
import FleetStore from '../../../../../../../../store/FleetStore/FleetStore'
import DashboardMapStore from '../../../../../../../../store/DashboardMapStore/DashboardMapStore'
import ReactMapGL, { Layer, Marker, Popup, Source, NavigationControl } from 'react-map-gl'
import { FaMapMarkerAlt } from '@react-icons/all-files/fa/FaMapMarkerAlt'
import VehicleStore from '../../../../../../../../store/VehicleStore/VehicleStore'
import MahindraVehicle from '../../../../../../../../assets/mahindra.png'
import MahindraVehicleOff from '../../../../../../../../assets/mahindra_off.png'
import TruckVehicle from '../../../../../../../../assets/truck.png'
import TruckVehicleOff from '../../../../../../../../assets/truck_off.png'
import VehicleDefault from '../../../../../../../../assets/vehicle_on.png'
import VehicleOff from '../../../../../../../../assets/vehicle_off.png'

export const RealtimeMap = observer(function RealtimeMapComponent() {
    const mapStyle = {
        width: '100%',
        height: '98vh',
    }

    const [showPopup, setPopup] = useState({
        show: false,
        longitude: 0.0,
        latitude: 0.0,
        ref: '',
    })

    const returnHeading = vehicle => {
        return { transform: `rotate(${vehicle.heading}deg)` }
    }

    const returnVehicleImage = vehicle => {
        switch (FleetStore.fleet.vehicle) {
            case 'mahindra':
                if (vehicle.is_ignition_on) {
                    return MahindraVehicle
                } else {
                    return MahindraVehicleOff
                }
            case 'truck':
                if (vehicle.is_ignition_on) {
                    return TruckVehicle
                } else {
                    return TruckVehicleOff
                }
            default:
                if (vehicle.is_ignition_on) {
                    return VehicleDefault
                } else {
                    return VehicleOff
                }
        }
    }

    const navControlStyle = {
        right: 5,
        bottom: 25,
    }

    useEffect(() => {
        DashboardMapStore.generateGeoJson()
        DashboardMapStore.updateViewport({
            latitude: FleetStore.fleet.latitude,
            longitude: FleetStore.fleet.longitude,
            zoom: FleetStore.fleet.zoom,
        })
    }, [])

    return (
        FleetStore.vehicles && (
            <div>
                <ReactMapGL
                    dragRotate={false}
                    mapboxApiAccessToken={'pk.eyJ1IjoiYWZyb2xhYnNyaWNoYXJkIiwiYSI6ImNrZndsM2NneDFtM3gzMnBkOXNmdWlmYjQifQ.hxu87TsIwxtUwlU6iX7Q-w'}
                    mapStyle={'mapbox://styles/mapbox/streets-v11?optimize=true'}
                    minZoom={3}
                    {...DashboardMapStore.viewport}
                    {...mapStyle}
                    onViewportChange={v => DashboardMapStore.updateViewport(v)}>
                    <NavigationControl showCompass={false} style={navControlStyle} />
                    {FleetStore.vehicles.map(value => {
                        return (
                            <Marker
                                key={value.key}
                                latitude={value.position.latitude}
                                longitude={value.position.longitude}
                                onClick={() => {
                                    VehicleStore.select(value)
                                }}>
                                <div className="cursor-pointer vehicle-marker">
                                    <img
                                        alt="vehicle"
                                        className={`vehicle ${VehicleStore.vehicleHovered ? 'opacity-50' : ''} ${
                                            VehicleStore.vehicleHovered && VehicleStore.vehicleHovered.key === value.key ? 'opacity-100 z-100' : ''
                                        }`}
                                        src={returnVehicleImage(value)}
                                        style={returnHeading(value)}
                                    />
                                    <div
                                        className={`absolute -top-5 bg-indigo-300 text-white rounded p-1 -z10 ${VehicleStore.vehicleHovered ? 'opacity-50' : 'opacity-90'} ${
                                            VehicleStore.vehicleHovered && VehicleStore.vehicleHovered.key === value.key ? 'opacity-100 bg-indigo-700 z-100' : ''
                                        }`}>
                                        {value.vehicle_number}
                                        <svg className="absolute text-black h-2 w-full left-0 top-full text-blue-400" x="0px" y="0px" viewBox="0 0 255 255" xmlSpace="preserve">
                                            <polygon className="fill-current" points="0,0 127.5,127.5 255,0" />
                                        </svg>
                                    </div>
                                </div>
                            </Marker>
                        )
                    })}

                    <Source id={'waypoint-polygons'} type={'geojson'} data={DashboardMapStore.geojson} />

                    <Layer
                        id={'waypoint-layer'}
                        type={'fill'}
                        source={'waypoint-polygons'}
                        paint={{
                            'fill-color': '#8DD8FF',
                            'fill-opacity': 0.2,
                        }}
                    />

                    {FleetStore.waypoints.map(value => {
                        return (
                            <Marker key={value.key} latitude={value.latitude} longitude={value.longitude}>
                                <FaMapMarkerAlt
                                    className={'stop-marker'}
                                    onMouseOver={() =>
                                        setPopup({
                                            show: true,
                                            latitude: value.latitude,
                                            longitude: value.longitude,
                                            ref: value.name,
                                        })
                                    }
                                    onMouseLeave={() =>
                                        setPopup({
                                            show: false,
                                            latitude: value.latitude,
                                            longitude: value.longitude,
                                            ref: value.name,
                                        })
                                    }
                                />
                            </Marker>
                        )
                    })}
                    {showPopup.show && (
                        <Popup latitude={showPopup.latitude} longitude={showPopup.longitude} closeButton={false}>
                            <div className={'stop-marker-name'}>{showPopup.ref}</div>
                        </Popup>
                    )}
                </ReactMapGL>
            </div>
        )
    )
})
