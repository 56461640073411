import { Map } from 'immutable'

export default Map([
    [0, 'Ignition - Off'],
    [1, 'Ignition - On'],
    [2, 'Timed Event'],
    [3, 'Early Warning Arm'],
    [4, 'Early Warning Disarm'],
    [5, 'Alarm Violation'],
    [6, 'Battery Monitoring - Inactive'],
    [7, 'Battery Monitoring - Active'],
    [8, 'Battery Reconnect'],
    [9, 'Battery Disconnect'],
    [10, 'Internal Battery Fault'],
    [11, 'Internal Battery Fault Cleared'],
    [12, 'Never got GPS position'],
    [13, 'Never got GPS position Cleared'],
    [14, 'Immobilize Active'],
    [15, 'Immobilize Inactive'],
    [16, 'Digital Input 1 - Inactive'],
    [17, 'Digital Input 1 - Active'],
    [18, 'Digital Input 2 - Inactive'],
    [19, 'Digital Input 2 - Active'],
    [20, 'Digital Input 3 - Inactive'],
    [21, 'Digital Input 3 - Active'],
    [22, 'Digital Input 4 - Inactive'],
    [23, 'Digital Input 4 - Active'],
    [27, 'Mobile Eye - Forward Collision Warning'],
    [28, 'Mobile Eye - Urban Forward Collision Warning'],
    [29, 'Mobile Eye - Lane Departure Warning'],
    [30, 'Mobile Eye - Headway Warning'],
    [31, 'Mobile Eye - Traffic Sign Recognition'],
    [32, 'Analogue Input 1 - Inactive'],
    [33, 'Analogue Input 1 - Active'],
    [34, 'Analogue Input 2 - Inactive'],
    [35, 'Analogue Input 2 - Active'],
    [36, 'Analogue Input 3 - Inactive'],
    [37, 'Analogue Input 3 - Active'],
    [38, 'Analogue Input 4 - Inactive'],
    [39, 'Analogue Input 4 - Active'],
    [48, 'No-Go Zone Violation - Inactive'],
    [49, 'No-Go Zone Violation - Active'],
    [50, 'Go Zone Violation - Inactive'],
    [51, 'Go Zone Violation - Active'],
    [52, 'Over Speeding Reset'],
    [53, 'Over Speeding'],
    [54, 'Over Revving Inactive'],
    [55, 'Over Revving Active'],
    [56, 'Harsh Cornering'],
    [58, 'Harsh Braking'],
    [59, 'Harsh Acceleration'],
    [60, 'Freewheeling'],
    [61, 'Excessive Idling'],
    [62, 'Maximum Speed'],
    [63, 'AGPS Download Complete'],
    [64, 'Response to Position Poll'],
    [65, 'Periodic Event'],
    [66, 'Timed Event [Alternative]'],
    [67, 'Distance'],
    [68, 'Dynamic'],
    [70, 'Over Green Band Inactive'],
    [71, 'Over Green Band Active'],
    [72, 'Under Green Band Inactive'],
    [73, 'Under Green Band Active'],
    [80, 'Leave Service'],
    [81, 'Enter Service'],
    [82, 'Self Test Error - CCU'],
    [83, 'GPS Antenna Tamper'],
    [84, 'RTC Failure'],
    [85, 'Valid Driver ID Detected'],
    [86, 'Ignition on with no Driver Id'],
    [87, 'Invalid Driver ID Detected'],
    [88, 'Wrong Client Code'],
    [89, 'Wrong License Type'],
    [90, 'Wrong Sub License Type'],
    [91, 'Public License Required'],
    [92, 'Drivers License Expired'],
    [93, 'Public License Expired'],
    [94, 'Heart Beat'],
    [96, 'No communication to GPS unit'],
    [97, 'Parameter Checksum mismatch'],
    [98, 'Logging Index Checksum mismatch'],
    [99, 'Main Flash Index Checksum mismatch'],
    [100, 'Secondary Flash Index Checksum mismatch'],
    [101, 'Battery Charging Parameters Checksum mismatch'],
    [102, 'Real Time Clock Error'],
    [103, 'Crash Sense with GPS data'],
    [104, 'Crash Sense without GPS data'],
    [105, 'VID Data'],
    [106, 'GeoFence Violation'],
    [107, 'GeoFence Violation [Alternative]'],
    [108, 'Light Impact'],
    [109, 'Medium Impact'],
    [110, 'Serious Impact Level 1'],
    [111, 'Serious Impact Level 2'],
    [113, 'VID Self Test Error'],
    [114, 'Trailer Unhitch'],
    [115, 'Trailer Hitch'],
    [116, 'Ext Power with monitoring Inactive'],
    [117, 'Ext Power with monitoring Active'],
    [118, 'Ext Pwr Disconnect - noGPS - Inactive'],
    [119, 'Ext Pwr Disconnect - noGPS - Active'],
    [120, 'Ext Pwr Disconnect - In unhitch Zone - Inactive'],
    [121, 'Ext Pwr Disconnect - In unhitch Zone - Active'],
    [122, 'Ext Pwr Disconnect - Out unhitch Zone - Inactive'],
    [123, 'Ext Pwr Disconnect - Out unhitch Zone - Active'],
    [124, 'Trailer TUZ cleared'],
    [125, 'Odometer Updated'],
    [126, 'All GPS GNGZ Cleared'],
    [127, 'Last Known GPS Position'],
    [128, 'Driver Acknowledgement for overspeeding'],
    [129, 'Rapid lane change event'],
    [130, 'GSM antenna has been disconnected from the unit'],
    [131, 'Internal battery disconnected'],
    [132, 'Internal battery reconnected'],
    [133, 'GPRS data exceeds threshold'],
    [134, 'DFD Acknowledge – Overrev Violation'],
    [135, 'DFD Acknowledge – Over Green Band Violation'],
    [136, 'DFD Acknowledge – Under Green Band Violation'],
    [137, 'DFD Acknowledge – Harsh Braking Violation'],
    [138, 'DFD Acknowledge – Harsh Acceleration Violation'],
    [139, 'DFD Acknowledge – Harsh Cornering Violation'],
    [140, 'DFD Acknowledge – Excessive Idle Violation'],
    [141, 'DFD Acknowledge – Freewheeling Violation'],
    [142, 'DFD Acknowledge - Overspeed Violation'],
    [143, 'Private Trip'],
    [144, 'Business Trip'],
    [145, 'OTA Event'],
    [146, 'Border Signpost Detected'],
    [147, 'DFD Heartbeat'],
    [148, 'DFD Not-Acknowledge – Overspeed Violation'],
    [149, 'DFD Not-Acknowledge – Overrev Violation'],
    [150, 'DFD Not-Acknowledge – Over Green Band Violation'],
    [151, 'DFD Not-Acknowledge – Under Green Band Violation'],
    [152, 'DFD Not-Acknowledge – Harsh Braking Violation'],
    [153, 'DFD Not-Acknowledge – Harsh Acceleration Violation'],
    [154, 'DFD Not-Acknowledge – Harsh Cornering Violation'],
    [155, 'DFD Not-Acknowledge – Excessive Idle Violation'],
    [156, 'DFD Not-Acknowledge – Freewheeling Violation'],
    [157, 'Theft ignition violation ID-tag'],
    [158, 'GeoFence Violated ID-tag'],
    [159, 'Driver list updated'],
    [160, 'GeoFence Violated KRA'],
    [161, 'Vehicle Stationary'],
    [162, 'Vehicle Moving'],
    [163, 'Temperature reset'],
    [164, 'Fuel Loss'],
    [165, 'Waypoint Entered'],
    [166, 'Waypoint Exit'],
    [167, 'Unauthorised Stop'],
    [168, 'Overspeeding in Speedzone'],
    [169, 'New Driver Detected'],
    [170, 'RF Jamming Inactive'],
    [171, 'Jamming Active'],
    [172, 'GSM Jamming Inactive'],
    [173, 'GSM Jamming Active'],
    [174, 'GPS Jamming Inactive'],
    [175, 'GPS Jamming Active'],
    [176, 'RF Beacon Inactive'],
    [177, 'RF Beacon Active'],
    [178, 'Disarmed after Violation'],
    [179, 'Max Harsh Acceleration'],
    [180, 'Max Harsh Braking'],
    [181, 'Max Harsh Cornering'],
    [182, 'Max Rapid Lane Change'],
    [183, 'Max Excessive Idling'],
    [184, 'Max Overspeeding'],
    [185, 'Temperature High Active'],
    [186, 'Temperature Low Active'],
    [187, 'Remote Safe Area Inactive'],
    [188, 'Remote Safe Area Active'],
    [189, 'RFID Tag Read Report'],
    [190, 'JPOD Sleep'],
    [191, 'JPOD Wakeup'],
    [192, 'CAN Report'],
    [194, 'Short-Range Jamming Inactive'],
    [195, 'Short-Range Jamming Active'],
    [196, 'Area list updated on the unit'],
    [197, 'Incoming Voice Call Inactive'],
    [198, 'Incoming Voice Call Active'],
    [199, 'Outgoing Voice Call Inactive'],
    [200, 'Outgoing Voice Call Active'],
    [201, 'DFD Disconnected'],
    [202, 'DFD Connected'],
    [203, 'CAN Bus Error Detected'],
    [206, 'Paired Nano Conn Lost'],
    [209, 'Temperature'],
])
