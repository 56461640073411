let baseApiUrl, baseWebSocketUrl, keycloakConfig, runningInProd

const hostname = window && window.location && window.location.hostname

switch (hostname) {
    case 'fleet.labs.ws':
        baseApiUrl = 'https://fleet.api.labs.ws'
        baseWebSocketUrl = 'wss://dev.realtime.fleet.labs.ws/query/'
        runningInProd = true
        keycloakConfig = {
            "realm": "platform",
            "url": "https://identity.gometroapp.com/auth",
            "ssl-required": "external",
            "clientId": "gometro-move-application",
            "public-client": true,
            "confidential-port": 0
        }
        break
    case 'dev.fleet.labs.ws':
        baseApiUrl = 'https://dev.fleet.api.labs.ws'
        baseWebSocketUrl = 'wss://dev.realtime.fleet.labs.ws/query/'
        runningInProd = true
        keycloakConfig = {
            "realm": "platform",
            "url": "https://identity.staging.gometroapp.com/auth/",
            "ssl-required": "external",
            "clientId": "gometro-fleet-dashboard",
            "public-client": true,
            "confidential-port": 0
        }
        break
    default:
        baseApiUrl = 'http://127.0.0.1:5000'
        baseWebSocketUrl = 'ws://localhost:8000/query/'
        runningInProd = false
        keycloakConfig = {
            "realm": "demo",
            "url": "http://localhost:8080/auth",
            "ssl-required": "external",
            "clientId": "gometro",
            "public-client": true,
            "confidential-port": 0
        }
}

export const BASE_API_URL = baseApiUrl
export const BASE_WEBSOCKET_URL = baseWebSocketUrl
export const KEYCLOAK_CONFIG = keycloakConfig
export const RUNNING_IN_PROD = runningInProd
