import * as ReactDOM from 'react-dom'
import { Router, Route, Switch } from 'react-router-dom'
import { createRenderer } from 'fela'
import { RendererProvider } from 'react-fela'
// Styles
// Import Main styles for this application
import './scss/tailwind.scss'

// Containers
import Base from './containers/Base'
import { Provider } from 'mobx-react'
import VehicleStore from './store/VehicleStore/VehicleStore'
import LogStore from './store/LogStore/LogStore'
import WaybillStore from './store/WaybillStore/WaybillStore'
import WaypointStore from './store/WaypointStore/WaypointStore'
import UserStore from './store/UserStore/UserStore'
import UsersStore from './store/UsersStore/UsersStore'
import DutyStore from './store/DutyStore/DutyStore'
import FleetStore from './store/FleetStore/FleetStore'
import DashboardMapStore from './store/DashboardMapStore/DashboardMapStore'

import { apiFetchFleets } from './api'
import HttpService from './services/HttpService'
import { createBrowserHistory } from 'history'
import DriverStore from './store/DriverStore/DriverStore'
import UserService from './services/UserService'
import { TripsStore } from './store/TripsStore/TripsStore'
import { RUNNING_IN_PROD } from './environment'
import { client } from './api/graphql'
import { ApolloProvider } from '@apollo/client'

const renderer = createRenderer()
export const goHistory = createBrowserHistory()
const renderApp = () =>
    ReactDOM.render(
        <ApolloProvider client={client}>
            <Provider
                vehicleStore={VehicleStore}
                driverStore={DriverStore}
                logStore={LogStore}
                waybillStore={WaybillStore}
                waypointStore={WaypointStore}
                tripsStore={TripsStore}
                dutyStore={DutyStore}
                userStore={UserStore}
                usersStore={UsersStore}
                fleetStore={FleetStore}
                dashboardMapStore={DashboardMapStore}>
                <RendererProvider renderer={renderer}>
                    <Router history={goHistory}>
                        <Switch>
                            <Route path="/" component={Base} />
                        </Switch>
                    </Router>
                </RendererProvider>
            </Provider>
        </ApolloProvider>,
        document.getElementById('root')
    )

const fetchData = function () {
    apiFetchFleets()
}

if (RUNNING_IN_PROD) {
    UserService.initKeycloak(fetchData, renderApp)
} else {
    fetchData()
    renderApp()
}
HttpService.configure()
