import axios from 'axios'
import ky, { NormalizedOptions } from 'ky'
import bourne from '@hapi/bourne'
import isString from 'lodash/isString'
import VehicleStore from '../store/VehicleStore/VehicleStore'
import UserService from '../services/UserService'
import LogStore from '../store/LogStore/LogStore'
import WaypointStore from '../store/WaypointStore/WaypointStore'
import UserStore from '../store/UserStore/UserStore'
import DutyStore from '../store/DutyStore/DutyStore'
import { goHistory } from '../index'
import UsersStore from '../store/UsersStore/UsersStore'
import DriverStore from '../store/DriverStore/DriverStore'
import FleetStore from '../store/FleetStore/FleetStore'
import TripsStore from '../store/TripsStore/TripsStore'
import { BASE_API_URL, RUNNING_IN_PROD } from '../environment'
import NotificationStore from '../store/NotificationStore/NotificationStore'

export async function apiFetchUserRole() {
    return axios
        .get(`${BASE_API_URL}/user/fetch`, {
            headers: {
                Authorization: `${UserService.getToken()}`,
            },
        })
        .then(res => UserStore.updateRole(res.data.user))
        .catch(error => {
            // Error
            switch (error.response.status) {
                case 403:
                    goHistory.push('/unauthorised')
                    break
                default:
                    break
            }
        })
}

export async function apiFetchVehicles() {
    return axios
        .get(`${BASE_API_URL}/vehicles/fetch`, {
            headers: {
                Authorization: `${UserService.getToken()}`,
            },
        })
        .then(res => VehicleStore.updateVehicles(res.data.vehicles, res.data.unlinked))
        .catch(error => {
            // Error
            switch (error.response.status) {
                case 403:
                    goHistory.push('/unauthorised')
                    break
                default:
                    break
            }
        })
}

export async function apiFetchDrivers() {
    return axios
        .get(`${BASE_API_URL}/drivers/fetch`, {
            headers: {
                Authorization: `${UserService.getToken()}`,
            },
        })
        .then(res => DriverStore.updateDrivers(res.data.drivers))
        .catch(error => {
            // Error
            switch (error.response.status) {
                case 403:
                    goHistory.push('/unauthorised')
                    break
                default:
                    break
            }
        })
}

export async function apiFetchUsers() {
    return axios
        .get(`${BASE_API_URL}/users/fetch`, {
            headers: {
                Authorization: `${UserService.getToken()}`,
            },
        })
        .then(res => UsersStore.updateUsers(res.data.users))
        .catch(error => {
            // Error
            switch (error.response.status) {
                case 403:
                    goHistory.push('/unauthorised')
                    break
                default:
                    break
            }
        })
}

export async function apiFetchFleets() {
    return axios
        .get(`${BASE_API_URL}/fleets/fetch`, {
            headers: {
                Authorization: `${UserService.getToken()}`,
            },
        })
        .then(res => FleetStore.updateFleets(res.data.fleets))
        .catch(error => {
            // Error
            switch (error.response.status) {
                case 403:
                    goHistory.push('/unauthorised')
                    break
                default:
                    break
            }
        })
}

export async function apiFetchSelectedFleet(slug: string) {
    return axios
        .get(`${BASE_API_URL}/fleet/fetch/${slug}`, {
            headers: {
                Authorization: `${UserService.getToken()}`,
            },
        })
        .then(res => FleetStore.fleetSelected(res.data.fleet))
        .catch(error => {
            // Error
            switch (error.response.status) {
                case 403:
                    goHistory.push('/unauthorised')
                    break
                default:
                    break
            }
        })
}

export async function apiFetchDutyStatus(dutyKey: string) {
    return axios
        .get(`${BASE_API_URL}/duty/${dutyKey}/status`, {
            headers: {
                Authorization: `${UserService.getToken()}`,
            },
        })
        .then(res => DutyStore.setSelectedDutyETA(res.data))
        .catch(error => {
            // Error
            switch (error.response?.status) {
                case 403:
                    goHistory.push('/unauthorised')
                    break
                default:
                    break
            }
        })
}

export async function apiFetchLogs(start: number, end: number, skip: number, fleet: string, type: string) {
    const payload = {
        start: start,
        end: end,
        skip: skip,
        fleet: fleet,
        type: type,
    }
    LogStore.updateFetchingData()
    return axios
        .post(
            `${BASE_API_URL}/logs/fetch`,
            {
                payload,
            },
            {
                headers: {
                    Authorization: `${UserService.getToken()}`,
                },
            }
        )
        .then(res => {
            if (skip > 0) {
                LogStore.concatLogs(res.data.logs)
            } else {
                LogStore.updateLogs(res.data.logs)
            }
            LogStore.updateFetchingData()
        })
        .catch(error => {
            // Error
            switch (error.response.status) {
                case 403:
                    goHistory.push('/unauthorised')
                    break
                default:
                    break
            }
        })
}

export async function apiFetchWaypoints() {
    return axios
        .get(`${BASE_API_URL}/waypoints/fetch`, {
            headers: {
                Authorization: `${UserService.getToken()}`,
            },
        })
        .then(res => WaypointStore.updateWaypoints(res.data.waypoints))
        .catch(error => {
            // Error
            switch (error.response.status) {
                case 403:
                    goHistory.push('/unauthorised')
                    break
                default:
                    break
            }
        })
}

export async function apiFetchDuties() {
    return axios
        .get(`${BASE_API_URL}/duties/fetch`, {
            headers: {
                Authorization: `${UserService.getToken()}`,
            },
        })
        .then(res => DutyStore.updateDuties(res.data.duties))
        .catch(error => {
            // Error
            switch (error.response.status) {
                case 403:
                    goHistory.push('/unauthorised')
                    break
                default:
                    break
            }
        })
}

export async function apiCancelDuty(dutyKey: string) {
    return axios
        .get(`${BASE_API_URL}/duty/${dutyKey}/cancel`, {
            headers: {
                Authorization: `${UserService.getToken()}`,
            },
        })
        .then(() => {
            NotificationStore.setToast('Duty was cancelled successfully.', true, true)
            apiFetchDuties()
        })
        .catch(error => {
            // Error
            NotificationStore.setToast('Duty was not cancelled.', true, false)
            switch (error.response?.status) {
                case 403:
                    goHistory.push('/unauthorised')
                    break
                default:
                    break
            }
        })
}

export async function apiFetchTrips() {
    return axios
        .get(`${BASE_API_URL}/trips/fetch`, {
            headers: {
                Authorization: `${UserService.getToken()}`,
            },
        })
        .then(res => TripsStore.updateTrips(res.data.trips))
        .catch(error => {
            // Error
            switch (error.response.status) {
                case 403:
                    goHistory.push('/unauthorised')
                    break
                default:
                    break
            }
        })
}

export async function apiCreateTrip(payload: any) {
    return axios
        .post(
            `${BASE_API_URL}/trips/create`,
            {
                payload,
            },
            {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `${UserService.getToken()}`,
                },
            }
        )
        .then(() => {
            NotificationStore.setToast('Trip was created successfully.', true, true)
            apiFetchTrips()
        })
        .catch(error => {
            // Error
            NotificationStore.setToast('Trip was not created.', true, false)
            switch (error.response?.status) {
                case 403:
                    goHistory.push('/unauthorised')
                    break
                default:
                    break
            }
        })
}

export async function apiEditTrip(payload: any) {
    return axios
        .post(
            `${BASE_API_URL}/trips/edit`,
            {
                payload,
            },
            {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `${UserService.getToken()}`,
                },
            }
        )
        .then(() => {
            NotificationStore.setToast('Trip was updated successfully.', true, true)
            apiFetchTrips()
        })
        .catch(error => {
            // Error
            NotificationStore.setToast('Trip was not updated.', true, false)
            switch (error.response?.status) {
                case 403:
                    goHistory.push('/unauthorised')
                    break
                default:
                    break
            }
        })
}

export async function apiUploadWaypoints(features: any) {
    const payload = {
        features: features,
    }
    return axios
        .post(
            `${BASE_API_URL}/waypoints/upload`,
            {
                payload,
            },
            {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `${UserService.getToken()}`,
                },
            }
        )
        .then(() => {
            NotificationStore.setToast('Waypoint(s) were created successfully.', true, true)
            apiFetchSelectedFleet(FleetStore.fleet.slug)
        })
        .catch(error => {
            // Error
            NotificationStore.setToast('Waypoint(s) were not created.', true, false)
            switch (error.response?.status) {
                case 403:
                    goHistory.push('/unauthorised')
                    break
                default:
                    break
            }
        })
}

export async function apiUpdateWaypoint(payload: any) {
    return axios
        .post(
            `${BASE_API_URL}/waypoint/update`,
            {
                payload,
            },
            {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `${UserService.getToken()}`,
                },
            }
        )
        .then(() => {
            NotificationStore.setToast('Waypoint was updated successfully', true, true)
            apiFetchSelectedFleet(FleetStore.fleet.slug)
        })
        .catch(error => {
            // Error
            NotificationStore.setToast('Waypoint was not updated.', true, false)
            switch (error.response?.status) {
                case 403:
                    goHistory.push('/unauthorised')
                    break
                default:
                    break
            }
        })
}

export async function apiCreateDuty(payload: any) {
    return axios
        .post(
            `${BASE_API_URL}/duty/create`,
            {
                payload,
            },
            {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `${UserService.getToken()}`,
                },
            }
        )
        .then(() => {
            NotificationStore.setToast('Vehicle was dispatched successfully.', true, true)
            apiFetchDuties()
        })
        .catch(error => {
            // Error
            NotificationStore.setToast('Vehicle was not dispatched.', true, false)
            switch (error.response?.status) {
                case 403:
                    goHistory.push('/unauthorised')
                    break
                default:
                    break
            }
        })
}

export async function apiCreateDriver(payload: object) {
    return axios
        .post(
            `${BASE_API_URL}/drivers/create`,
            {
                payload,
            },
            {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `${UserService.getToken()}`,
                },
            }
        )
        .then(apiFetchDrivers)
        .catch(error => {
            // Error
            switch (error.response.status) {
                case 403:
                    goHistory.push('/unauthorised')
                    break
                default:
                    break
            }
        })
}

export async function apiCreateWaypoint(payload: object) {
    return axios
        .post(
            `${BASE_API_URL}/waypoint/create`,
            {
                payload,
            },
            {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `${UserService.getToken()}`,
                },
            }
        )
        .then(() => {
            NotificationStore.setToast('Waypoint was created successfully.', true, true)
            apiFetchDrivers()
        })
        .catch(error => {
            // Error
            NotificationStore.setToast('Waypoint was not created.', true, false)
            switch (error.response?.status) {
                case 403:
                    goHistory.push('/unauthorised')
                    break
                default:
                    break
            }
        })
}

export async function apiCreateFleet(payload: object) {
    return axios
        .post(
            `${BASE_API_URL}/fleet/create`,
            {
                payload,
            },
            {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `${UserService.getToken()}`,
                },
            }
        )
        .then(apiFetchFleets)
        .catch(error => {
            // Error
            switch (error.response.status) {
                case 403:
                    goHistory.push('/unauthorised')
                    break
                default:
                    break
            }
        })
}

export async function apiLinkVehicle(payload: object) {
    return axios
        .post(
            `${BASE_API_URL}/vehicle/link`,
            {
                payload,
            },
            {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `${UserService.getToken()}`,
                },
            }
        )
        .then(apiFetchVehicles)
        .catch(error => {
            // Error
            switch (error.response.status) {
                case 403:
                    goHistory.push('/unauthorised')
                    break
                default:
                    break
            }
        })
}

export async function apiUpdateVehicle(payload: object) {
    return axios
        .post(
            `${BASE_API_URL}/vehicle/update`,
            {
                payload,
            },
            {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `${UserService.getToken()}`,
                },
            }
        )
        .then(apiFetchVehicles)
        .catch(error => {
            // Error
            switch (error.response.status) {
                case 403:
                    goHistory.push('/unauthorised')
                    break
                default:
                    break
            }
        })
}

export async function apiDispatchDriver(waybill: string, vehicle: string) {
    const payload = {
        waybill: waybill,
        vehicle: vehicle,
    }

    return axios
        .post(
            `${BASE_API_URL}/dispatch_duty`,
            {
                payload,
            },
            {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `${UserService.getToken()}`,
                },
            }
        )
        .then(apiFetchDuties)
        .catch(error => {
            // Error
            switch (error.response.status) {
                case 403:
                    goHistory.push('/unauthorised')
                    break
                default:
                    break
            }
        })
}

export async function apiCreateUser(payload: object) {
    return axios
        .post(
            `${BASE_API_URL}/manage_user`,
            {
                payload,
            },
            {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `${UserService.getToken()}`,
                },
            }
        )
        .then(apiFetchUsers)
        .catch(error => {
            // Error
            switch (error.response.status) {
                case 403:
                    goHistory.push('/unauthorised')
                    break
                default:
                    break
            }
        })
}

export const baseApi = ky.create({
    prefixUrl: BASE_API_URL,
    parseJson: t => bourne.parse(t),
    hooks: {
        beforeRequest: [
            (r: Request) => {
                if (!r.headers.has('Authorization') && RUNNING_IN_PROD) {
                    const token = UserService.getToken()
                    if (token != null) {
                        r.headers.set('Authorization', token)
                    } else {
                        throw new Error('No auth token available')
                    }
                }
                return r
            },
        ],
        afterResponse: [
            (request: Request, options: NormalizedOptions, response: Response) => {
                if (response.status === 403) {
                    goHistory.push('/unauthorised')
                    return response
                }
                return response
            },
        ],
    },
})

export const requestLogs = async (fleet: string, start: Date | string, end: Date | string): Promise<any> => {
    return baseApi
        .post('logs/fetch', {
            json: {
                fleet,
                start: isString(start) ? start : (start as Date).toISOString(),
                end: isString(end) ? end : (end as Date).toISOString(),
            },
            timeout: false,
        })
        .json()
}
