import React from 'react'

export function useInputFormFields<T>(initialValues: T) {
    const [inputFormFields, setFormFields] = React.useState<T>(initialValues)
    const createInputChangeHandler = (key: keyof T) => (
        e: React.ChangeEvent<HTMLInputElement>,
    ) => {
        const value = e.target.value
        setFormFields((prev: T) => ({...prev, [key]: value}))
    }
    const setInputChangeHandler = (key: keyof T, value: any) => {
        setFormFields((prev: T) => ({...prev, [key]: value}))
    }
    return {inputFormFields, createInputChangeHandler, setInputChangeHandler}
}

export function useSelectFormFields<T>(initialValues: T) {
    const [selectFormFields, setFormFields] = React.useState<T>(initialValues)
    const createSelectChangeHandler = (key: keyof T) => (
        e: React.ChangeEvent<HTMLSelectElement>,
    ) => {
        const value = e.target.value
        setFormFields((prev: T) => ({...prev, [key]: value}))
    }
    return {selectFormFields, createSelectChangeHandler}
}
