import { createRxDatabase, addPouchPlugin, getRxStoragePouch, RxCollection } from 'rxdb'
import { RUNNING_IN_PROD } from '../environment'
import { logSchema } from './schemas/log'

addPouchPlugin(RUNNING_IN_PROD ? require('pouchdb-adapter-idb') : require('pouchdb-adapter-memory'))

export const DB_DRIVER = RUNNING_IN_PROD ? 'idb' : 'memory'

export const database = createRxDatabase<{
    logs: RxCollection<{ id: string; when: number; fleet: string; type: string; tags: string[]; date: any; relatedEntities: { [k: string]: any } }>
}>({
    name: 'fleet',
    storage: getRxStoragePouch(DB_DRIVER),
}).then(async db => {
    await db.addCollections({
        logs: {
            schema: logSchema,
        },
    })
    return db
})
