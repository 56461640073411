import React, { useState } from 'react'
import { CInput } from '@coreui/react'
import { useInputFormFields, useSelectFormFields } from '../../../../util'
import { apiCreateUser } from '../../../../api'
import FleetStore from '../../../../store/FleetStore/FleetStore'

export function UseCreateUser() {
    let selectedFleets = []

    const [checkedState] = useState(new Array(FleetStore.fleets.length).fill(false))

    const { inputFormFields, createInputChangeHandler } = useInputFormFields({
        userEmail: '',
    })

    const { selectFormFields, createSelectChangeHandler } = useSelectFormFields({
        userRole: '',
    })

    const handleOnChange = key => {
        // @ts-ignore
        selectedFleets.push(key)
    }

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault()
        const payload = {
            email: inputFormFields.userEmail,
            role: selectFormFields.userRole,
            fleet: selectedFleets,
        }

        apiCreateUser(payload)
    }

    return (
        <form onSubmit={handleSubmit}>
            <CInput
                type="text"
                id="username"
                name="username"
                placeholder="Email"
                className="go-input"
                value={inputFormFields.userEmail}
                onChange={createInputChangeHandler('userEmail')}
            />

            <select className="form-control form-control-lg go-select" value={selectFormFields.userRole} onChange={createSelectChangeHandler('userRole')}>
                <option value="operator">Operator</option>
                <option value="admin">Admin</option>
            </select>

            {FleetStore.fleets.map((value, index) => {
                return (
                    <li key={value.key}>
                        <div className="left-section">
                            <input
                                type="checkbox"
                                id={`custom-checkbox-${index}`}
                                name={value.name}
                                value={value.key}
                                checked={checkedState[index]}
                                onChange={() => handleOnChange(value.key)}
                            />
                            <label htmlFor={`custom-checkbox-${index}`}>{value.name}</label>
                        </div>
                    </li>
                )
            })}

            <button className="btn btn-primary" type="submit">
                Create
            </button>
        </form>
    )
}

export default UseCreateUser
