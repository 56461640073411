import { action, makeAutoObservable } from 'mobx'
import { FleetProps, TripProps, VehicleProps, WaypointProps } from '../../interfaces'
import { StartLiveFeed } from '../../services/LiveFeedService'

export class FleetStore {
    fleet: any = {}
    vehicles: VehicleProps[] = []
    waypoints: WaypointProps[] = []
    trips: TripProps[] = []
    duties: any

    fleets: FleetProps[] = []
    selectedFleet: any = {}

    constructor() {
        makeAutoObservable(this)
    }

    @action fleetSelected(data: any) {
        this.fleet = data.fleet
        this.waypoints = data.waypoints
        this.vehicles = data.vehicles
        this.trips = data.trips
        this.duties = data.duties

        this.duties.active.forEach(duty => {
            duty.vehicle = data.vehicles.find(o => o.key === duty.vehicle)
        })
        this.duties.inactive.forEach(duty => {
            duty.vehicle = data.vehicles.find(o => o.key === duty.vehicle)
        })

        this.trips.forEach(trip => {
            trip.schedule.forEach(schedule => {
                schedule.waypoint = data.waypoints.find(o => o.key === schedule.stop)
            })
        })

        StartLiveFeed(data.fleet.slug)
    }

    @action updateFleets(fleets: FleetProps[]) {
        this.fleets = fleets
    }

    @action updateWaypoint(waypoint: any) {
        let selectedWaypointIndex = this.waypoints.findIndex(o => o.key === waypoint.key)
        this.waypoints[selectedWaypointIndex] = waypoint
    }

    @action updateVehicleLocation(vehicle: any) {
        let selectedVehicleIndex = this.vehicles.findIndex(o => o.registration === vehicle.registration)
        if (typeof selectedVehicleIndex !== 'undefined') {
            this.vehicles[selectedVehicleIndex].server_updated_at = new Date(vehicle?.datetime)
            this.vehicles[selectedVehicleIndex].position = {
                latitude: vehicle?.latitude,
                longitude: vehicle?.longitude,
                altitude: vehicle?.altitude,
            }
        }
    }

    selectFleet(fleet: string) {
        this.selectedFleet = this.fleets.find(o => o.key === fleet)
    }
}

export default new FleetStore()
