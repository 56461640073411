import { webSocket } from 'rxjs/webSocket'
import { retry } from 'rxjs/operators'
import FleetStore from 'store/FleetStore/FleetStore'
import { BASE_WEBSOCKET_URL } from '../environment'

export async function StartLiveFeed(slug: string) {
    // @ts-ignore
    const subject = webSocket(`${BASE_WEBSOCKET_URL}${slug}/ws`).pipe(
        retry()
    );

    subject.subscribe({
        next: (data) => {
            FleetStore.updateVehicleLocation(data)
        },
        error: (data) => {
            console.log(data)
        },
    })
}
