import React, { useState } from 'react'
import { CInput, CInputCheckbox } from '@coreui/react'
import { useInputFormFields, useSelectFormFields } from '../../../../util'
import { apiCreateFleet } from '../../../../api'

export function UseCreateFleet() {
    const [isChecked, setIsChecked] = useState(false)

    const { inputFormFields, createInputChangeHandler } = useInputFormFields({
        fleetName: '',
        fleetSlug: '',
        fleetLat: '',
        fleetLng: '',
        fleetZoom: '',
    })

    const { selectFormFields, createSelectChangeHandler } = useSelectFormFields({
        vehicleType: '',
    })

    const handleOnChange = () => {
        setIsChecked(!isChecked)
    }

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault()
        const payload = {
            name: inputFormFields.fleetName,
            slug: inputFormFields.fleetSlug,
            latitude: parseFloat(inputFormFields.fleetLat),
            longitude: parseFloat(inputFormFields.fleetLng),
            // tslint:disable-next-line:radix
            zoom: parseInt(inputFormFields.fleetZoom),
            vehicle: selectFormFields.vehicleType,
            dispatch: isChecked,
        }

        apiCreateFleet(payload)
    }

    return (
        <form onSubmit={handleSubmit}>
            <div>
                <CInput
                    type="text"
                    id="fleet-name"
                    name="fleet-name"
                    placeholder="Fleet Name"
                    className="go-input"
                    value={inputFormFields.fleetName}
                    onChange={createInputChangeHandler('fleetName')}
                />
                <CInput
                    type="text"
                    id="url"
                    name="url"
                    placeholder="Url slug (/fleet/<slug>)"
                    className="go-input"
                    value={inputFormFields.fleetSlug}
                    onChange={createInputChangeHandler('fleetSlug')}
                />
                <CInput
                    type="text"
                    id="center-lat"
                    name="center-lat"
                    placeholder="Default center latitude"
                    className="go-input"
                    value={inputFormFields.fleetLat}
                    onChange={createInputChangeHandler('fleetLat')}
                />
                <CInput
                    type="text"
                    id="center-lon"
                    name="center-lon"
                    placeholder="Default center longitude"
                    className="go-input"
                    value={inputFormFields.fleetLng}
                    onChange={createInputChangeHandler('fleetLng')}
                />
                <CInput
                    type="text"
                    id="zoom"
                    name="zoom"
                    placeholder="Default zoom"
                    className="go-input"
                    value={inputFormFields.fleetZoom}
                    onChange={createInputChangeHandler('fleetZoom')}
                />
                <select className="form-control form-control-lg" value={selectFormFields.vehicleType} onChange={createSelectChangeHandler('vehicleType')}>
                    <option value={'vehicle'}>Default</option>
                    <option value={'mahindra'}>Mahindra</option>
                    <option value={'truck'}>Truck</option>
                </select>
                <CInputCheckbox value={'dispatch'} checked={isChecked} onChange={handleOnChange} id="dispatch" label="Dispatch management" /> Dispatch
                <button className="btn btn-primary" type="submit">
                    Create
                </button>
            </div>
        </form>
    )
}

export default UseCreateFleet
