import { observer } from 'mobx-react'
import WaypointStore from '../../../../../../store/WaypointStore/WaypointStore'
import FleetStore from '../../../../../../store/FleetStore/FleetStore'
import { StopsMap } from './components/Map/Map'
import { WaypointModal } from './components/Modals/Waypoint'
import { StopsWaypoints } from './components/Waypoints/Waypoints'
import { CreateWaypointModal } from './components/Modals/CreateWaypoint'
import { WaypointBatchModal } from './components/Modals/WaypointBatch'

export const PlanningWaypointsView = observer(function PlanningWaypointsViewComponent() {
    return FleetStore.fleet.key ? (
        <div className="flex-1 relative z-0 flex overflow-hidden">
            <aside className="hidden relative xl:order-first xl:flex xl:flex-col flex-shrink-0 w-96 border-r border-gray-200">
                <StopsWaypoints />
            </aside>
            <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none xl:order-last">
                <div className="absolute inset-0 py-4 px-4 sm:px-4 lg:px-4">
                    <div className="h-full border-2 border-gray-200 rounded-lg overflow-hidden">
                        <StopsMap />
                    </div>
                </div>
            </main>

            {WaypointStore.selectedWaypoint && <WaypointModal />}
            {WaypointStore.create && <CreateWaypointModal />}
            {WaypointStore.tempBatch && <WaypointBatchModal />}
        </div>
    ) : (
        <div className="w-full h-full fixed block bottom-0 left-0 bg-gray-600 opacity-75 z-100">
            <svg className="animate-spin -ml-1 mr-3 h-20 w-20 text-white absolute top-1/2 left-1/2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                />
            </svg>
        </div>
    )
})
