import { Component } from 'react'
import UserService from '../../services/UserService'

class Unauthorised extends Component {
    render() {
        return (
            <div className={'unauthed-container'}>
                <h1>Unauthorised Access</h1>
                <p>Please contact support for more information.</p>
                <button
                    className={'btn btn-secondary'}
                    onClick={() => {
                        UserService.doLogout()
                    }}>
                    Logout {UserService.getEmail()}
                </button>
            </div>
        )
    }
}

export default Unauthorised
