import { Component } from 'react'
import VehicleOff from '../../assets/vehicle_off.png'
import VehicleDefault from '../../assets/vehicle_on.png'
import MahindraVehicle from '../../assets/mahindra.png'
import MahindraVehicleOff from '../../assets/mahindra_off.png'
import TruckVehicle from '../../assets/truck.png'
import TruckVehicleOff from '../../assets/truck_off.png'
import VehicleActive from '../../assets/vehicle.png'
import { VehicleProps } from '../../interfaces'
import FleetStore from '../../store/FleetStore/FleetStore'
import VehicleStore from '../../store/VehicleStore/VehicleStore'

export class Vehicle extends Component<VehicleProps, {}> {
    result: any

    constructor(props: VehicleProps) {
        super(props)
        this.result = {}
    }

    render() {
        const style = {
            transform: `rotate(${this.props.heading}deg)`,
        }

        let vehicleImg = ''

        switch (FleetStore.fleet.vehicle) {
            case 'mahindra':
                if (this.props.is_ignition_on) {
                    vehicleImg = MahindraVehicle
                } else {
                    vehicleImg = MahindraVehicleOff
                }
                break
            case 'truck':
                if (this.props.is_ignition_on) {
                    vehicleImg = TruckVehicle
                } else {
                    vehicleImg = TruckVehicleOff
                }
                break
            default:
                if (this.props.is_ignition_on) {
                    vehicleImg = VehicleDefault
                } else {
                    vehicleImg = VehicleOff
                }
        }

        if (this.props.duties) {
            this.result = this.props.duties.find(e => e.vehicle === this.props.vehicle_number)
        }

        if (Object.keys(this.result).length > 0) {
            vehicleImg = VehicleActive
        }

        return (
            <div className="cursor-pointer">
                <img
                    alt="vehicle"
                    className={`vehicle ${VehicleStore.vehicleHovered ? 'opacity-50' : ''} ${
                        VehicleStore.vehicleHovered && VehicleStore.vehicleHovered.vehicle_number === this.props.vehicle_number ? 'opacity-100 z-100' : ''
                    }`}
                    src={vehicleImg}
                    style={style}
                />
                <div
                    className={`absolute -top-5 bg-indigo-300 text-white rounded p-1 -z10 ${VehicleStore.vehicleHovered ? 'opacity-50' : 'opacity-90'} ${
                        VehicleStore.vehicleHovered && VehicleStore.vehicleHovered.vehicle_number === this.props.vehicle_number ? 'opacity-100 bg-indigo-700 z-100' : ''
                    }`}>
                    {this.props.vehicle_number}
                    <svg className="absolute text-black h-2 w-full left-0 top-full text-blue-400" x="0px" y="0px" viewBox="0 0 255 255" xmlSpace="preserve">
                        <polygon className="fill-current" points="0,0 127.5,127.5 255,0" />
                    </svg>
                </div>
            </div>
        )
    }
}

export default Vehicle
