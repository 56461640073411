import { action, makeAutoObservable } from 'mobx'
import FleetStore from '../FleetStore/FleetStore'

export class WaypointMapStore {
    waypointMap: any = {}
    viewport: any = {
        latitude: FleetStore.fleet.latitude,
        longitude: FleetStore.fleet.longitude,
        zoom: FleetStore.fleet.zoom,
    }
    geojson: any = {
        type: 'FeatureCollection',
        features: [],
    }

    constructor() {
        makeAutoObservable(this)
    }

    @action getDashboardMapRef(map: any) {
        this.waypointMap = map
    }

    @action generateGeoJson() {
        let generatedGeoJson: any = {
            type: 'FeatureCollection',
            features: [],
        }

        FleetStore.waypoints.forEach(waypoint => {
            generatedGeoJson.features.push({
                type: 'Feature',
                properties: {},
                geometry: {
                    type: 'Polygon',
                    coordinates: waypoint.locations.coordinates,
                },
            })
        })

        this.geojson = generatedGeoJson
    }

    @action updateViewport(viewport) {
        this.viewport = viewport
    }
}

export default new WaypointMapStore()
