import { observer } from 'mobx-react'
import WaypointStore from '../../../../../../../../store/WaypointStore/WaypointStore'
import { apiUploadWaypoints } from '../../../../../../../../api'
import { useState } from 'react'

export const WaypointBatchModal = observer(function WaypointBatchModalComponent() {
    const [batchRadius, setBatchRadius] = useState('5')

    return (
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full sm:p-0 z-1000">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true" />

            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                &#8203;
            </span>
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div>
                    <div className="space-y-8 divide-y divide-gray-200">
                        <div>
                            <nav className="h-60 overflow-y-auto" aria-label="Directory">
                                <div className="relative">
                                    <div className="z-10 sticky top-0 border-t border-b border-gray-200 bg-gray-50 px-6 py-1 text-sm font-medium text-gray-500">
                                        <h3>Upload Waypoints</h3>
                                    </div>
                                    <ul role="list" className="relative z-0 divide-y divide-gray-200">
                                        {WaypointStore.tempBatch
                                            .slice()
                                            .sort((a, b) => a.name.localeCompare(b.name))
                                            .map(value => {
                                                return (
                                                    <li key={value.name} className="bg-white">
                                                        <div className="relative px-6 py-5 flex items-center space-x-3 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500">
                                                            <div className="flex-1 min-w-0">
                                                                <a href="#" className="focus:outline-none">
                                                                    <p className="text-sm font-medium text-gray-900">{value.name}</p>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </li>
                                                )
                                            })}
                                    </ul>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
                <div className="mt-6">
                    <div className="sm:col-span-3">
                        <label htmlFor="radius" className="block text-sm font-medium text-gray-700">
                            Radius (KM)
                        </label>
                        <div className="mt-1">
                            <input
                                type="text"
                                name="radius"
                                id="radius"
                                onChange={e => setBatchRadius(e.target.value)}
                                value={batchRadius}
                                autoComplete="5"
                                className="shadow-sm focus:ring-indigo-500 p-2 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            />
                        </div>
                    </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                    <button
                        type="button"
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-400 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                        onClick={() => {
                            WaypointStore.setBatchRadius(parseFloat(batchRadius))
                            apiUploadWaypoints(WaypointStore.tempBatch)
                            WaypointStore.populateBatch(null)
                        }}>
                        Save
                    </button>
                    <button
                        type="button"
                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                        onClick={() => {
                            WaypointStore.populateBatch(null)
                        }}>
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    )
})
