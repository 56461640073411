import Keycloak from 'keycloak-js'
import { KEYCLOAK_CONFIG } from '../environment'

console.log(KEYCLOAK_CONFIG)

const _kc = Keycloak(KEYCLOAK_CONFIG)

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 * @param fetchData
 */
const initKeycloak = (onAuthenticatedCallback, fetchData) => {
    _kc.init({
        checkLoginIframe: false,
        onLoad: 'check-sso',
        pkceMethod: 'S256',
    }).then(authenticated => {
        if (authenticated) {
            onAuthenticatedCallback()
            fetchData()

            // TODO this was a lazy fix to a problem in prod.
            //  we should only check for an expired token on API requests - not poll.
            let sleep = time => new Promise(resolve => setTimeout(resolve, time))

            let poll = (promiseFn, time) => promiseFn().then(sleep(time).then(() => poll(promiseFn, time)))

            poll(() => new Promise(() => updateToken(true)), 60000)
        } else {
            doLogin()
        }
    })
}

const doLogin = _kc.login

const doLogout = _kc.logout

const getToken = () => _kc.token

const isLoggedIn = () => !!_kc.token

const updateToken = successCallback => _kc.updateToken(120).then(successCallback).catch(doLogin)

const decodeJWT = token => {
    var base64Url = token.split('.')[1]
    var base64 = base64Url.replace('-', '+').replace('_', '/')
    return JSON.stringify(JSON.parse(window.atob(base64)), null, 4)
}

// @ts-ignore
const getUsername = () => _kc.tokenParsed?.preferred_username

// @ts-ignore
const getEmail = () => _kc.tokenParsed?.email

const hasRole = roles => roles.some(role => _kc.hasRealmRole(role))

const UserService = {
    initKeycloak,
    doLogin,
    doLogout,
    isLoggedIn,
    getToken,
    decodeJWT,
    updateToken,
    getUsername,
    getEmail,
    hasRole,
}

export default UserService
