import { makeAutoObservable } from 'mobx'
import { DriverProps } from '../../interfaces'

export class DriverStore {
    fleet: DriverProps[] = []

    constructor() {
        makeAutoObservable(this)
    }

    updateDrivers(drivers: any) {
        this.fleet = drivers
    }
}

export default new DriverStore()
