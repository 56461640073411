import { observer } from 'mobx-react'
import VehicleStore from '../../../../../../../../store/VehicleStore/VehicleStore'
import FleetStore from '../../../../../../../../store/FleetStore/FleetStore'
import { apiCreateDuty, apiFetchSelectedFleet } from '../../../../../../../../api'
import { goHistory } from '../../../../../../../../index'

export const PlanningDutiesMain = observer(function PlanningDutiesMainComponent() {

    const dispatchDuty = (vehicleKey: string) => {

        const payload = {
            'vehicle': vehicleKey,
            'trips': [],
            'active': true,
        }
        apiCreateDuty(payload)
        apiFetchSelectedFleet(FleetStore.fleet.slug).then(() => {
            goHistory.push(`/fleet/${FleetStore.fleet.slug}/monitoring/duties`)
        })
    }

    return VehicleStore.selectedForDispatch && (
        <main className="py-10">
            {/* Page header */}
            <div
                className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
                <div className="flex items-center space-x-5">
                    <div>
                        <h1 className="text-2xl font-bold text-gray-900">{VehicleStore.selectedForDispatch.vehicle_number}</h1>
                        <div className="mt-2 sm:flex sm:justify-between">
                            <div className="sm:flex">
                                <p className="flex items-center text-sm text-gray-500">
                                    <svg xmlns="http://www.w3.org/2000/svg"
                                         className="h-6 w-6 text-black"
                                         fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path
                                            d="M9 17a2 2 0 11-4 0 2 2 0 014 0zM19 17a2 2 0 11-4 0 2 2 0 014 0z"/>
                                        <path strokeLinecap="round" strokeLinejoin="round"
                                              strokeWidth={2}
                                              d="M13 16V6a1 1 0 00-1-1H4a1 1 0 00-1 1v10a1 1 0 001 1h1m8-1a1 1 0 01-1 1H9m4-1V8a1 1 0 011-1h2.586a1 1 0 01.707.293l3.414 3.414a1 1 0 01.293.707V16a1 1 0 01-1 1h-1m-6-1a1 1 0 001 1h1M5 17a2 2 0 104 0m-4 0a2 2 0 114 0m6 0a2 2 0 104 0m-4 0a2 2 0 114 0"/>
                                    </svg>
                                    {VehicleStore.selectedForDispatch.registration}
                                </p>
                                <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6">
                                    <svg xmlns="http://www.w3.org/2000/svg"
                                         className="h-6 w-6 text-black"
                                         fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round"
                                              strokeWidth={2}
                                              d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"/>
                                    </svg>
                                    {VehicleStore.selectedForDispatch.fleet_number ? VehicleStore.selectedForDispatch.fleet_number : 'N/A'}
                                </p>

                                <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6">
                                    <svg xmlns="http://www.w3.org/2000/svg"
                                         className="h-6 w-6 text-black"
                                         fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round"
                                              strokeWidth={2}
                                              d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z"/>
                                    </svg>
                                    {VehicleStore.selectedForDispatch.is_ignition_on ? 'On' : 'Off'}
                                </p>

                                <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-black"
                                         fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round"
                                              strokeWidth={2} d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"/>
                                    </svg>
                                    {VehicleStore.selectedForDispatch.speed}KM/H
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div
                className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                <div className="space-y-6 lg:col-start-1 lg:col-span-2">
                    {/* Description list*/}
                    <section aria-labelledby="applicant-information-title">
                        {!VehicleStore.selectedForDispatch.duty ? <div className="bg-white shadow sm:rounded-lg">
                            <div className="px-4 py-5 sm:px-6">
                                <h2 id="applicant-information-title"
                                    className="text-lg leading-6 font-medium text-gray-900">
                                    Select your trips.
                                </h2>
                                <p className="mt-1 max-w-2xl text-sm text-gray-500">A duty without trips will still send
                                    notifications.</p>
                            </div>
                            <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                                <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                    <div className="sm:col-span-1">
                                        <dt className="text-sm font-medium text-gray-500">Trip number</dt>
                                        <dd className="mt-1 text-sm text-gray-900">Select</dd>
                                    </div>
                                    <div className="sm:col-span-2">
                                        <dt className="text-sm font-medium text-gray-500">Duty information</dt>
                                        <dd className="mt-1 text-sm text-gray-900">
                                            Trip stops and information coming soon.
                                        </dd>
                                    </div>
                                </dl>
                            </div>
                        </div> : <div className="bg-white shadow sm:rounded-lg">
                            <div className="px-4 py-5 sm:px-6">
                                <h2 id="applicant-information-title"
                                    className="text-lg leading-6 font-medium text-gray-900">
                                    Vehicle is already on duty.
                                </h2>
                                <p className="mt-1 max-w-2xl text-sm text-gray-500">A duty without trips will still send
                                    notifications.</p>
                            </div>
                            <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                                <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                    <div className="sm:col-span-1">
                                        <dt className="text-sm font-medium text-gray-500">Duty information.</dt>
                                    </div>
                                </dl>
                            </div>
                        </div>}
                    </section>
                </div>

                <section style={{display: VehicleStore.selectedForDispatch.duty ? 'none' : 'block'}}
                         aria-labelledby="timeline-title" className="lg:col-start-3 lg:col-span-1">
                    <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">
                        <h2 id="timeline-title" className="text-lg font-medium text-gray-900">
                            Dispatch {VehicleStore.selectedForDispatch.vehicle_number} on an empty Duty
                        </h2>
                        <div className="mt-6 flex flex-col justify-stretch">
                            <button
                                type="button"
                                onClick={() => {
                                    dispatchDuty(VehicleStore.selectedForDispatch.key)
                                }}
                                className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-green-400 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                            >
                                Dispatch now
                            </button>
                        </div>
                    </div>
                </section>
            </div>
        </main>
    )
})
