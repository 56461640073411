import { observer } from 'mobx-react'
import DutyStore from '../../../../../../../../store/DutyStore/DutyStore'
import FleetStore from '../../../../../../../../store/FleetStore/FleetStore'
import { apiCancelDuty, apiFetchDutyStatus, apiFetchSelectedFleet } from '../../../../../../../../api'
import { useEffect } from 'react'
import { format, parseISO } from 'date-fns'

export const RealtimeDutiesMain = observer(function RealtimeDutiesComponent() {
    const cancelDuty = (dutyKey: string) => {
        apiCancelDuty(dutyKey).then(() => {
            apiFetchSelectedFleet(FleetStore.fleet.slug)
            DutyStore.selectDuty(null)
        })
    }

    useEffect(() => {
        if (DutyStore.selectedDuty !== null) {
            apiFetchDutyStatus(DutyStore.selectedDuty.key)
        }
    }, [DutyStore.selectedDuty])

    const scheduleAdherence = step => {
        let actioned = new Date(step.actioned_at)
        let scheduled = new Date(step.scheduled_time)

        // Early Arrivals Green
        // if (scheduled.getTime() > actioned.getTime()) {
        //     console.log('Early')
        //     return 'bg-green-300'
        // }

        if (scheduled.getTime() < actioned.getTime()) {
            console.log('Late')
            return 'bg-red-300'
        }
        console.log('Exactly on time')
        return 'bg-white text-black'
    }

    return DutyStore.selectedDuty ? (
        <main className="py-10">
            <div className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
                <div className="flex items-center space-x-5">
                    <div>
                        <h1 className="text-2xl font-bold text-gray-900">{DutyStore.selectedDuty.ref ? DutyStore.selectedDuty.ref : 'Generic Trip'}</h1>
                        <div className="mt-2 sm:flex sm:justify-between">
                            <div className="sm:flex">
                                <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-black" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                    {format(parseISO(DutyStore.selectedDuty.created_at), 'PPPPpppp')}
                                </p>
                                <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-black" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path d="M9 17a2 2 0 11-4 0 2 2 0 014 0zM19 17a2 2 0 11-4 0 2 2 0 014 0z" />
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="M13 16V6a1 1 0 00-1-1H4a1 1 0 00-1 1v10a1 1 0 001 1h1m8-1a1 1 0 01-1 1H9m4-1V8a1 1 0 011-1h2.586a1 1 0 01.707.293l3.414 3.414a1 1 0 01.293.707V16a1 1 0 01-1 1h-1m-6-1a1 1 0 001 1h1M5 17a2 2 0 104 0m-4 0a2 2 0 114 0m6 0a2 2 0 104 0m-4 0a2 2 0 114 0"
                                        />
                                    </svg>
                                    {DutyStore.selectedDuty.vehicle.registration}
                                </p>
                                <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-black" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z"
                                        />
                                    </svg>
                                    {DutyStore.selectedDuty.vehicle.is_ignition_on ? 'On' : 'Off'}
                                </p>
                                <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-black" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8" />
                                    </svg>
                                    {DutyStore.selectedDuty.vehicle.speed}KM/H
                                </p>
                                <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6">
                                    <button
                                        type="button"
                                        onClick={() => {
                                            cancelDuty(DutyStore.selectedDuty.key)
                                        }}
                                        className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-500 hover:bg-red-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                                        Cancel duty
                                    </button>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {DutyStore.selectedDuty.scheduled_events && DutyStore.selectedDuty.scheduled_events.length > 0 ? (
                <div className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                    <div className="space-y-6 min-w-full lg:col-start-1 lg:col-span-2">
                        <div className="flex flex-col">
                            <div className="-my-2 min-w-full sm:-mx-6 lg:-mx-8">
                                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                        <table className="min-w-full divide-y divide-gray-200">
                                            <thead className="bg-gray-50">
                                                <tr>
                                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                        Waypoint/Stop
                                                    </th>
                                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                        Event
                                                    </th>
                                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                        Scheduled Time
                                                    </th>
                                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                        Estimated Time
                                                    </th>
                                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                        Actual Time
                                                    </th>
                                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                        Status
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {DutyStore.selectedDuty.scheduled_events.map((step, stepIdx) => (
                                                    <tr
                                                        key={step.order}
                                                        className={
                                                            // stepIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'

                                                            scheduleAdherence(step)
                                                        }>
                                                        <td className="px-6 py-4 overflow-hidden text-sm font-medium text-gray-900">
                                                            {FleetStore.waypoints.find(o => o.key === step.waypoint_key)?.name}
                                                        </td>
                                                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{step.event}</td>
                                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{parseISO(step.scheduled_time).toLocaleString()}</td>
                                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                            {DutyStore.selectedDutyETA && DutyStore.selectedDutyETA.stops[stepIdx]?.estimated_time
                                                                ? parseISO(DutyStore.selectedDutyETA.stops[stepIdx].estimated_time).toLocaleString()
                                                                : '-'}
                                                        </td>
                                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                            {step.actioned_at && parseISO(step.actioned_at).toLocaleString()}
                                                        </td>
                                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                            {step.status === 'COMPLETED' ? (
                                                                <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                                                    {step.status}
                                                                </span>
                                                            ) : (
                                                                <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-400 text-yellow-800">
                                                                    {step.status}
                                                                </span>
                                                            )}
                                                            {parseISO(step.actioned_at).getTime() > parseISO(step.scheduled_time).getTime() ? (
                                                                <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-400 text-red-800">Late</span>
                                                            ) : (
                                                                ''
                                                            )}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                    <div className="space-y-6 lg:col-start-1 lg:col-span-2">
                        {/* Description list*/}
                        <section aria-labelledby="applicant-information-title">
                            <div className="bg-white shadow sm:rounded-lg">
                                <div className="px-4 py-5 sm:px-6">
                                    <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                                        The duty has no schedule.
                                    </h2>
                                    <p className="mt-1 max-w-2xl text-sm text-gray-500">A duty without a schedule will still send notifications.</p>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            )}
        </main>
    ) : (
        <main className="py-10">
            <div className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
                <div className="flex items-center space-x-5">
                    <div>
                        <h1 className="text-2xl font-bold text-gray-900">Please select a duty.</h1>
                    </div>
                </div>
            </div>
        </main>
    )
})
