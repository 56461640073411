import { observer } from 'mobx-react'
import FleetStore from '../../../../store/FleetStore/FleetStore'
import { goHistory } from '../../../../index'
import { apiFetchSelectedFleet } from '../../../../api'
import { useState } from 'react'

export const SelectFleet = observer(function SelectFleetView() {
    const [loadingFleet, setLoadingFleet] = useState(false)

    const toggleLoadingFleet = () => {
        setLoadingFleet(!loadingFleet)
    }

    const handleFleetSelect = e => {
        toggleLoadingFleet()
        apiFetchSelectedFleet(e.target[0].value).then(() => {
            toggleLoadingFleet()
            goHistory.push(`/fleet/${e.target[0].value}/monitoring/vehicles`)
        })
        e.preventDefault()
    }

    return (
        <div className="max-w-md mx-auto py-20 sm:px-6 lg:px-8">
            {loadingFleet ? <div className="w-full h-full fixed block bottom-0 left-0 bg-gray-600 opacity-75 z-100">
                <svg className="animate-spin -ml-1 mr-3 h-20 w-20 text-white absolute top-1/2 left-1/2"
                     xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"/>
                    <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    />
                </svg>
            </div> : <div className="max-w-6xl mx-auto">
                <div className="bg-white shadow sm:rounded-lg">
                    <div className="px-4 py-5 sm:p-6">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">Select your Fleet</h3>
                        <div className="mt-2 max-w-xl text-sm text-gray-500">
                            <p>To access your dashboard please select a fleet.</p>
                        </div>
                        <form onSubmit={handleFleetSelect}>
                            <select
                                id="location"
                                name="location"
                                className="my-4 block w-full border pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
                                {FleetStore.fleets.map((value, index) => {
                                    return (
                                        <option key={index} value={value.slug}>
                                            {value.name}
                                        </option>
                                    )
                                })}
                            </select>
                            <button
                                type="submit"
                                className="text-center bg-green-400 px-4 py-2 w-full border border-gray-300 shadow-sm font-medium rounded-md text-white bg-white hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm">
                                Select
                            </button>
                        </form>
                    </div>
                </div>
            </div>}
        </div>
    )
})
