import { observer } from 'mobx-react'
import { useEffect, useState } from 'react'
import FleetStore from '../../../../../../../../store/FleetStore/FleetStore'
import WaypointStore from '../../../../../../../../store/WaypointStore/WaypointStore'
import WaypointMapStore from '../../../../../../../../store/WaypointMapStore/WaypointMapStore'
import NotificationStore from '../../../../../../../../store/NotificationStore/NotificationStore'

export const StopsWaypoints = observer(function StopsWaypointsComponent() {
    const [filteredWaypoints, setFilteredWaypoints] = useState(FleetStore.waypoints)

    const updateWaypoints = waypoints => {
        setFilteredWaypoints(waypoints)
    }

    useEffect(() => {
        // @ts-ignore
        setFilteredWaypoints(() => {
            return [...FleetStore.waypoints]
        })
    }, [FleetStore.waypoints])

    const handleSearch = e => {
        let currentList: any = []
        let newList: any

        if (e.target.value !== '') {
            currentList = FleetStore.waypoints
            newList = currentList.filter(item => {
                const lc = item.name.toLowerCase()
                const filter = e.target.value.toLowerCase()
                return lc.includes(filter)
            })
        } else {
            newList = FleetStore.waypoints
        }
        updateWaypoints(newList)
    }

    return (
        FleetStore.vehicles && (
            <nav className="h-full bg-white overflow-y-auto" aria-label="Directory">
                <div className="relative">
                    <div className="z-10 sticky top-0 border-t border-b border-gray-200 bg-gray-50 px-6 py-3 text-sm font-medium text-black">
                        <input
                            type="text"
                            name="desktop-trip-name"
                            id="desktop-trip-name"
                            className="mt-1 border block w-full pl-3 pr-2 py-1 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-md text-black rounded-md"
                            onChange={handleSearch}
                            placeholder="Search waypoints..."
                        />
                    </div>
                    <ul role="list" className="relative z-0 divide-y divide-gray-200">
                        <li
                            onClick={() => {
                                WaypointStore.toggleCreate()
                            }}>
                            <div className="block cursor-pointer hover:bg-gray-50 text-black hover:text-white hover:bg-green-400">
                                <div className="px-4 py-4 sm:px-6">
                                    <div className="flex items-center justify-between">
                                        <p className="text-sm font-medium truncate">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 float-left mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth={2}
                                                    d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                                                />
                                            </svg>
                                            New Waypoint/s
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li
                            onClick={() => {
                                NotificationStore.setToast('Please contact your fleet manager to delete a waypoint.', true, false)
                            }}>
                            <div className="block cursor-pointer hover:bg-gray-50 text-black hover:text-white hover:bg-red-500">
                                <div className="px-4 py-4 sm:px-6">
                                    <div className="flex items-center justify-between">
                                        <p className="text-sm font-medium truncate">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 float-left mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                            </svg>
                                            Delete Waypoint/s
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </li>
                        {filteredWaypoints
                            .slice()
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .map(value => {
                                return (
                                    <li
                                        key={value.key}
                                        onClick={() => {
                                            WaypointStore.select(value)
                                            WaypointMapStore.updateViewport({
                                                latitude: value.latitude,
                                                longitude: value.longitude,
                                                zoom: 16,
                                            })
                                        }}>
                                        <div className="transition duration-500 ease-in-out block cursor-pointer hover:bg-gray-50 text-black hover:text-white hover:bg-green-400">
                                            <div className="px-4 py-4 sm:px-6">
                                                <div className="flex items-center justify-between">
                                                    <p className="text-sm font-medium truncate">{value.name}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                )
                            })}
                    </ul>
                </div>
            </nav>
        )
    )
})
