import * as React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { Container } from 'reactstrap'
import Unauthorised from '../views/unauthorised/Unauthorised'
import Admin from '../views/admin/Admin'
import { Fleet } from '../views/fleet/Fleet'
import { SelectFleet } from '../views/fleet/views/SelectFleet/SelectFleet'

class Base extends React.Component {
    render() {
        return (
            <div className="app">
                {/*TODO: move Header component here from Dashboard view */}
                <div className="app-body">
                    {/*<Sidebar {...this.props} />*/}
                    <main className="main">
                        <Container fluid={true}>
                            <Switch>
                                <Route path="/fleet/:slug/:category/:view" component={Fleet} />
                                <Route path="/fleet/select" component={SelectFleet} />
                                <Route path="/unauthorised" component={Unauthorised} />
                                <Route path="/admin" component={Admin} />
                                <Redirect from="/" to="/fleet/select" />
                            </Switch>
                        </Container>
                    </main>
                    {/*<Aside />*/}
                </div>
                {/*<Footer />*/}
            </div>
        )
    }
}

export default Base
