import { action, makeAutoObservable } from 'mobx'
import { WaybillProps } from '../../interfaces'

export class WaybillStore {
    waybills: WaybillProps[] = []

    constructor() {
        makeAutoObservable(this)
    }

    @action updateWaybills(waybills: WaybillProps[]) {
        this.waybills = waybills

    }
}

export default new WaybillStore()
