import { ApolloClient, InMemoryCache, HttpLink, split, ApolloLink } from '@apollo/client'
import { WebSocketLink } from '@apollo/client/link/ws'
import { getMainDefinition, offsetLimitPagination } from '@apollo/client/utilities'
import UserService from '../../services/UserService'
import { BASE_API_URL } from '../../environment'

const httpLink = new ApolloLink((operation, forward) => {
    operation.setContext({
        headers: {
            authorization: () => UserService.getToken(),
        },
    })
    return forward(operation)
}).concat(
    new HttpLink({
        uri: `${BASE_API_URL}/ql`,
        fetchOptions: {},
    })
)
const wsLink = new WebSocketLink({
    uri: `${BASE_API_URL.replace('http', 'ws')}/ql`,
    options: {
        reconnect: true,
        lazy: true,
        connectionParams: () => ({
            authToken: UserService.getToken(),
        }),
    },
})
const splitLink = split(
    ({ query }) => {
        const definition = getMainDefinition(query)
        return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
    },
    wsLink,
    httpLink
)

export const client = new ApolloClient({
    link: splitLink,
    cache: new InMemoryCache({
        typePolicies: {
            Fleet: {
                fields: {
                    logs: {
                        ...offsetLimitPagination(),
                        // @ts-ignore
                        read(existing, { args: { offset = 0, limit = 100 } }) {
                            // A read function should always return undefined if existing is
                            // undefined. Returning undefined signals that the field is
                            // missing from the cache, which instructs Apollo Client to
                            // fetch its value from your GraphQL server.
                            return existing && existing.slice(offset, offset + limit)
                        },
                    },
                },
            },
        },
    }),
})
