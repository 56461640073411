import { observer } from 'mobx-react'
import FleetStore from '../../../../../../store/FleetStore/FleetStore'
import { PlanningTripsAside } from './components/Aside/Aside'
import { PlanningTripsMain } from './components/Main/Main'

export const PlanningTripsView = observer(function PlanningTripsComponent() {
    return FleetStore.fleet.key ? (
        <div className="flex-1 relative z-0 flex overflow-hidden">
            <PlanningTripsAside />
            <PlanningTripsMain />
        </div>
    ) : (
        <div className="w-full h-full fixed block bottom-0 left-0 bg-gray-600 opacity-75 z-100">
            <svg className="animate-spin -ml-1 mr-3 h-20 w-20 text-white absolute top-1/2 left-1/2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                />
            </svg>
        </div>
    )
})
