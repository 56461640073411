export const logSchema = {
    title: 'log schema',
    keyCompression: true,
    version: 0,
    primaryKey: {
        key: 'id',
        fields: ['when', 'type', 'fleet'],
        separator: '|',
    },
    type: 'object',
    properties: {
        id: {
            type: 'string',
        },
        when: {
            type: 'number',
            final: true,
        },
        fleet: {
            type: 'string',
            format: 'uuid',
            final: true,
        },
        type: {
            type: 'string',
            final: true,
        },
        tags: {
            type: 'array',
            items: {
                type: 'string',
            },
            final: true,
        },
        data: {
            type: ['object', 'null'],
        },
        relatedEntities: {
            type: 'object',
        },
    },
    required: ['when', 'fleet', 'type', 'tags'],
}
