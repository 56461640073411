import { observer } from 'mobx-react'
import FleetStore from '../../../../../../../../store/FleetStore/FleetStore'
import TripsStore from '../../../../../../../../store/TripsStore/TripsStore'
import { useInputFormFields, useSelectFormFields } from '../../../../../../../../util'
import { apiCreateTrip, apiEditTrip, apiFetchSelectedFleet } from '../../../../../../../../api'
import { useEffect } from 'react'

export const PlanningTripsMain = observer(function PlanningTripsMainComponent() {
    const { inputFormFields, createInputChangeHandler, setInputChangeHandler } = useInputFormFields({
        etaTime: '',
        tripName: '',
    })

    const { selectFormFields, createSelectChangeHandler } = useSelectFormFields({
        stop: '',
        event: '',
        alert: '',
        dayOffset: '',
    })

    useEffect(() => {
        setInputChangeHandler('tripName', TripsStore.selectedTrip.name)
    }, [TripsStore.selectedTrip])

    const addStop = () => {
        TripsStore.addStop(Object.assign({}, selectFormFields, inputFormFields), 'waypoint')
    }

    const createTrip = () => {
        const payload: any = {
            name: inputFormFields.tripName,
            fleet: FleetStore.fleet.key,
            schedule: TripsStore.selectedTrip.schedule,
        }

        if (TripsStore.selectedTrip?.key) {
            payload.key = TripsStore.selectedTrip.key
            apiEditTrip(payload).then(() => {
                apiFetchSelectedFleet(FleetStore.fleet.slug)
            })
        } else {
            apiCreateTrip(payload).then(() => {
                apiFetchSelectedFleet(FleetStore.fleet.slug)
            })
        }
    }

    // @ts-ignore
    return (
        FleetStore.vehicles && (
            <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none xl:order-last">
                <div className="absolute inset-0 py-4 px-4 sm:px-4 lg:px-4">
                    <div className="h-full border-2 border-gray-200 rounded-lg overflow-hidden">
                        <div className="p-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
                            <div className="text-lg leading-6 font-medium text-gray-900 mt-3 sm:mt-0 sm:ml-4">
                                <h1>{TripsStore.selectedTrip.key ? TripsStore.selectedTrip.name : 'New trip'}</h1>
                                <br />
                                <div className="grid grid-cols-9 gap-6">
                                    <div className="col-span-6 sm:col-span-5">
                                        <input
                                            type="text"
                                            name="desktop-trip-name"
                                            id="desktop-trip-name"
                                            value={inputFormFields.tripName}
                                            onChange={createInputChangeHandler('tripName')}
                                            className="mt-1 border block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-lg text-black rounded-md"
                                            placeholder="Trip name"
                                        />
                                    </div>

                                    <div className="col-span-9 sm:col-span-2">
                                        <button
                                            type="button"
                                            onClick={() => {
                                                createTrip()
                                            }}
                                            className="w-full mt-1 inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-400 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm">
                                            {TripsStore.selectedTrip.key ? 'Edit trip' : 'Create trip'}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col">
                            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                        <table className="min-w-full divide-y divide-gray-200">
                                            <thead className="bg-gray-50">
                                                <tr>
                                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                        Stop
                                                    </th>
                                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                        Event
                                                    </th>
                                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                        Alert
                                                    </th>
                                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                        ETA
                                                    </th>
                                                    <th scope="col" className="relative px-6 py-3">
                                                        <span className="sr-only">Edit</span>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr className="bg-white">
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                                        {FleetStore.waypoints.length > 0 ? (
                                                            <select
                                                                id="location"
                                                                name="location"
                                                                value={selectFormFields.stop}
                                                                onChange={createSelectChangeHandler('stop')}
                                                                className="mt-1 border block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-lg text-black rounded-md">
                                                                <option value={''}>Select stop</option>
                                                                {FleetStore.waypoints
                                                                    .slice()
                                                                    .sort((a, b) => a.name.localeCompare(b.name))
                                                                    .map(value => {
                                                                        return (
                                                                            <option key={value.key} value={value.key}>
                                                                                {value.name}
                                                                            </option>
                                                                        )
                                                                    })}
                                                            </select>
                                                        ) : (
                                                            'Please create a stop.'
                                                        )}
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                        <select
                                                            id="event"
                                                            name="event"
                                                            className="mt-1 border block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-lg text-black rounded-md"
                                                            value={selectFormFields.event}
                                                            onChange={createSelectChangeHandler('event')}>
                                                            <option value={''}>Select event type</option>
                                                            <option value={'arrival'}>Arrival</option>
                                                            <option value={'departure'}>Departure</option>
                                                        </select>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                        <select
                                                            id="alert"
                                                            name="alert"
                                                            className="mt-1 border block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-lg text-black rounded-md"
                                                            value={selectFormFields.alert}
                                                            onChange={createSelectChangeHandler('alert')}>
                                                            <option value={''}>Select notification</option>
                                                            <option value={'true'}>Always</option>
                                                            <option value={'false'}>Never</option>
                                                        </select>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                        <div className="grid grid-cols-4 gap-2">
                                                            <div className="col-span-3 sm:col-span-2">
                                                                <select
                                                                    id="day-offset"
                                                                    name="day-offset"
                                                                    className="mt-1 border block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-lg text-black rounded-md"
                                                                    defaultValue={'0'}
                                                                    value={selectFormFields.dayOffset}
                                                                    onChange={createSelectChangeHandler('dayOffset')}>
                                                                    <option value={''}>Select day offset</option>
                                                                    <option value={'0'}>Same Day</option>
                                                                    <option value={'1'}>Next Day</option>
                                                                </select>
                                                            </div>

                                                            <div className="col-span-1 sm:col-span-1">
                                                                <input
                                                                    value={inputFormFields.etaTime}
                                                                    onChange={createInputChangeHandler('etaTime')}
                                                                    className="mt-1 border block w-full pl-3 pr-2 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-lg text-black rounded-md"
                                                                    type="time"
                                                                />
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                                        <button
                                                            type="button"
                                                            onClick={() => {
                                                                addStop()
                                                            }}
                                                            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-400 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm">
                                                            Add stop
                                                        </button>
                                                    </td>
                                                </tr>
                                                {TripsStore.selectedTrip &&
                                                    TripsStore.selectedTrip.schedule.map((schedule, index) => (
                                                        <tr key={index} className={index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}>
                                                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                                                {FleetStore.waypoints.find(o => o.key === schedule.stop)?.name}
                                                            </td>
                                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{schedule.event}</td>
                                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{schedule.alert}</td>
                                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                                {new Date(schedule.date).toDateString()} {schedule.etaTime}
                                                            </td>
                                                            <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                                                <a href="#" className="text-indigo-600 hover:text-indigo-900" />
                                                            </td>
                                                        </tr>
                                                    ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        )
    )
})
