import { observer } from 'mobx-react'
import WaypointStore from '../../../../../../../../store/WaypointStore/WaypointStore'
import FleetStore from '../../../../../../../../store/FleetStore/FleetStore'

import xml2js from 'xml2js'
import { readString } from 'react-papaparse'

export const CreateWaypointModal = observer(function CreateWaypointModalComponent() {
    const handleFileInput = e => {
        const file = e.target.files[0]
        const fileType = file.name.split('.')[1]
        let fileData: any
        let fileReader = new FileReader()

        fileReader.readAsText(file)

        fileReader.onload = () => {
            fileData = fileReader.result

            switch (fileType) {
                case 'geojson': {
                    let features: any = []
                    const geoJSONData = JSON.parse(fileData)

                    for (const feature of geoJSONData.features) {
                        if (feature.geometry.type === 'Point') {
                            features.push({
                                latitude: feature.geometry.coordinates[1],
                                longitude: feature.geometry.coordinates[0],
                                radius_in_km: 5,
                                events: {
                                    arrived: true,
                                    departed: true,
                                },
                                name: feature.properties.name,
                                fleet: FleetStore.fleet.key,
                                optional_id: '',
                            })
                        }
                    }

                    WaypointStore.populateBatch(features)
                    WaypointStore.toggleCreate()
                    break
                }

                case 'kml': {
                    let features: any = []
                    let parseStringXML = xml2js.parseString

                    parseStringXML(fileData, (err, result) => {
                        const kmlData = result.kml.Document[0].Placemark

                        for (const placemark of kmlData) {
                            if (placemark.Point != null) {
                                const coordinates = placemark.Point[0].coordinates[0].trim().split(',')
                                features.push({
                                    latitude: coordinates[1],
                                    longitude: coordinates[0],
                                    radius_in_km: 5,
                                    events: {
                                        arrived: true,
                                        departed: true,
                                    },
                                    name: placemark.name[0],
                                    fleet: FleetStore.fleet.key,
                                    optional_id: '',
                                })
                            }
                        }
                    })

                    WaypointStore.populateBatch(features)
                    WaypointStore.toggleCreate()
                    break
                }

                case 'txt': {
                    let features: any = []
                    // const stops: any = readString(fileData, { header: true })
                    readString(fileData, {
                        worker: true,
                        complete: (stops: any) => {
                            for (const stop of stops.data) {
                                features.push({
                                    latitude: stop.stop_lat,
                                    longitude: stop.stop_lon,
                                    radius_in_km: 0.05,
                                    events: {
                                        arrived: true,
                                        departed: true,
                                    },
                                    name: stop.stop_name,
                                    fleet: FleetStore.fleet.key,
                                    optional_id: stop.stop_id,
                                })
                            }
                        }
                    })
                    WaypointStore.populateBatch(features)
                    WaypointStore.toggleCreate()
                    break
                }

                default: {
                    break
                }
            }
        }
    }

    return (
        <div
            className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full sm:p-0 z-1000">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"/>

            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                &#8203;
            </span>
            <div
                className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div>
                    <div className="mt-3 text-left sm:mt-5">
                        <div>
                            <h3 className="text-lg leading-6 font-medium text-gray-900">Create new Waypoint/s</h3>
                        </div>
                    </div>
                    <div className="space-y-8 divide-y divide-gray-200">
                        <div>
                            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                                <div
                                    className="sm:col-span-3 cursor-pointer"
                                    onClick={() => {
                                        WaypointStore.toggleCreate()

                                        WaypointStore.select({
                                            key: null,
                                            name: '',
                                            ref: '',
                                            latitude: '',
                                            longitude: '',
                                            radius: '',
                                            locations: {},
                                            events: {},
                                        })
                                    }}>
                                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                                        <div
                                            className="max-w-lg flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                                            <div className="space-y-1 cursor-pointer text-center">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="mx-auto h-12 w-12 text-gray-400"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor">
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth={2}
                                                        d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                                                    />
                                                </svg>

                                                <div className="flex text-sm text-gray-600">
                                                    <label
                                                        htmlFor="file-upload"
                                                        className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                                                        <span>Create Waypoint</span>
                                                        <input id="file-upload" name="file-upload" type="file"
                                                               className="sr-only"/>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="sm:col-span-3" onClick={() => {
                                }}>
                                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                                        <div
                                            className="max-w-lg flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                                            <div className="space-y-1 text-center">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="mx-auto h-12 w-12 text-gray-400"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor">
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth={2}
                                                        d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"
                                                    />
                                                </svg>

                                                <div className="flex text-sm text-gray-600">
                                                    <label
                                                        htmlFor="batch-upload"
                                                        className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                                                        <span>Batch upload GeoJson</span>
                                                        <input
                                                            id="batch-upload"
                                                            name="batch-upload"
                                                            type="file"
                                                            className="sr-only"
                                                            accept=".kml, .geojson, .txt"
                                                            onChange={handleFileInput}
                                                        />
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-5">
                    <button
                        type="button"
                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                        onClick={() => {
                            WaypointStore.toggleCreate()
                        }}>
                        Close
                    </button>
                </div>
            </div>
        </div>
    )
})
