import { action, makeAutoObservable } from 'mobx'
import { TripProps } from '../../interfaces'

export class TripsStore {
    schedule: TripProps[] = []
    selectedTrip: any = {'schedule': []}
    create: boolean = false

    constructor() {
        makeAutoObservable(this)
    }

    @action updateTrips(schedule: TripProps[]) {
        this.schedule = schedule

    }

    @action toggleCreate() {
        this.create = !this.create
    }

    @action selectTrip(trip: any) {
        this.selectedTrip = trip
    }

    @action addStop(stop: any, type: string) {
        stop.type = type
        if (this.selectedTrip.schedule.length > 0) {
            const previousDate = new Date(this.selectedTrip.schedule[this.selectedTrip.schedule.length - 1].date)
            stop.date = previousDate.setDate(previousDate.getDate() + parseInt(stop.dayOffset))
        } else {
            const newDate = new Date()
            stop.date = newDate.setDate(newDate.getDate() + parseInt(stop.dayOffset))
        }
        this.selectedTrip.schedule.push(stop)
    }
}

export default new TripsStore()
