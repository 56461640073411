import { observer } from 'mobx-react'
import { useState } from 'react'
import WaypointStore from '../../../../../../../../store/WaypointStore/WaypointStore'
import FleetStore from '../../../../../../../../store/FleetStore/FleetStore'
import { useInputFormFields } from '../../../../../../../../util'
import { apiUpdateWaypoint } from '../../../../../../../../api'

import xml2js from 'xml2js'

export const WaypointModal = observer(function WaypointModalComponent() {
    const [departedIsChecked, setDepartedIsChecked] = useState(WaypointStore.selectedWaypoint.events.departed)
    const [arrivedIsChecked, setArrivedIsChecked] = useState(WaypointStore.selectedWaypoint.events.arrived)

    const { inputFormFields, createInputChangeHandler, setInputChangeHandler } = useInputFormFields({
        key: WaypointStore.selectedWaypoint.key,
        name: WaypointStore.selectedWaypoint.name,
        ref: WaypointStore.selectedWaypoint.ref,
        latitude: WaypointStore.selectedWaypoint.latitude,
        longitude: WaypointStore.selectedWaypoint.longitude,
        radius: WaypointStore.selectedWaypoint.radius,
        fleet: FleetStore.fleet.key,
        locations: {},
        events: {},
    })

    const updateArrivedCheck = () => {
        setArrivedIsChecked(!arrivedIsChecked)
    }

    const updateDepartedCheck = () => {
        setDepartedIsChecked(!departedIsChecked)
    }

    const updateWaypoint = () => {
        inputFormFields.events = {
            departed: departedIsChecked,
            arrived: arrivedIsChecked,
        }

        inputFormFields.latitude = parseFloat(inputFormFields.latitude)
        inputFormFields.longitude = parseFloat(inputFormFields.longitude)

        if (inputFormFields.key === null) {
            delete inputFormFields.key
        }

        apiUpdateWaypoint(inputFormFields)
        WaypointStore.clearSelect()
    }

    const updateCustomGeofence = locations => {
        inputFormFields.locations = {
            coordinates: locations,
        }
    }

    const handleSingleFieldInput = e => {
        const file = e.target.files[0]
        const fileType = file.name.split('.')[1]
        let fileData: any
        let fileReader = new FileReader()

        fileReader.readAsText(file)

        fileReader.onload = () => {
            fileData = fileReader.result

            switch (fileType) {
                case 'geojson': {
                    const geoJSONData = JSON.parse(fileData)
                    let coordinates = []

                    for (const feature of geoJSONData.features) {
                        if (feature.geometry.type === 'Polygon') {
                            coordinates = feature.geometry.coordinates
                        }
                    }

                    inputFormFields.locations = {
                        coordinates: coordinates,
                    }
                    break
                }

                case 'kml': {
                    let parseStringXML = xml2js.parseString
                    let coordinates = []

                    parseStringXML(fileData, (err, result) => {
                        const kmlData = result.kml.Document[0].Placemark

                        for (const feature of kmlData) {
                            if (feature.Polygon != null) {
                                setInputChangeHandler('name', feature.name[0])
                                const coord = feature.Polygon[0].outerBoundaryIs[0].LinearRing[0].coordinates[0].trim().split('\n')
                                coordinates = coord.map(element => {
                                    const point = element.trim().split(',')
                                    return [point[0], point[1]]
                                })
                            }
                        }
                    })
                    updateCustomGeofence([coordinates])
                    // showUploadCustomGeoFenceModal()
                    break
                }

                default: {
                    break
                }
            }
        }

        fileReader.onerror = () => {
            alert(fileReader.error)
        }
    }

    return (
        WaypointStore.selectedWaypoint && (
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full sm:p-0 z-1000">
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true" />

                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                    &#8203;
                </span>
                <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                    <div>
                        <div className="mt-3 text-left sm:mt-5">
                            <div>
                                <h3 className="text-lg leading-6 font-medium text-gray-900">{WaypointStore.selectedWaypoint.name}</h3>
                            </div>
                            <form className="space-y-8 divide-y divide-gray-200">
                                <div className="space-y-8 divide-y divide-gray-200">
                                    <div>
                                        <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                                            <div className="sm:col-span-3">
                                                <label htmlFor="waypoint-name" className="block text-sm font-medium text-gray-700">
                                                    Waypoint Name
                                                </label>
                                                <div className="mt-1">
                                                    <input
                                                        type="text"
                                                        name="waypoint-name"
                                                        id="waypoint-name"
                                                        autoComplete="Waypoint"
                                                        onChange={createInputChangeHandler('name')}
                                                        value={inputFormFields.name}
                                                        className="shadow-sm focus:ring-indigo-500 p-2 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                                    />
                                                </div>
                                            </div>

                                            <div className="sm:col-span-3">
                                                <label htmlFor="waypoint-ref" className="block text-sm font-medium text-gray-700">
                                                    Waypoint Ref
                                                </label>
                                                <div className="mt-1">
                                                    <input
                                                        type="text"
                                                        name="waypoint-ref"
                                                        id="waypoint-ref"
                                                        autoComplete="Waypoint Ref"
                                                        onChange={createInputChangeHandler('ref')}
                                                        value={inputFormFields.ref}
                                                        className="shadow-sm focus:ring-indigo-500 p-2 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                                            <div className="sm:col-span-3">
                                                <label htmlFor="latitude" className="block text-sm font-medium text-gray-700">
                                                    Latitude
                                                </label>
                                                <div className="mt-1">
                                                    <input
                                                        type="text"
                                                        name="latitude"
                                                        id="latitude"
                                                        autoComplete="-31"
                                                        onChange={createInputChangeHandler('latitude')}
                                                        value={inputFormFields.latitude}
                                                        className="shadow-sm focus:ring-indigo-500 p-2 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                                    />
                                                </div>
                                            </div>

                                            <div className="sm:col-span-3">
                                                <label htmlFor="longitude" className="block text-sm font-medium text-gray-700">
                                                    Longitude
                                                </label>
                                                <div className="mt-1">
                                                    <input
                                                        type="text"
                                                        name="longitude"
                                                        id="longitude"
                                                        autoComplete="16"
                                                        onChange={createInputChangeHandler('longitude')}
                                                        value={inputFormFields.longitude}
                                                        className="shadow-sm focus:ring-indigo-500 p-2 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="mt-6">
                                            <div className="sm:col-span-3">
                                                <label htmlFor="radius" className="block text-sm font-medium text-gray-700">
                                                    Radius (KM)
                                                </label>
                                                <div className="mt-1">
                                                    <input
                                                        type="text"
                                                        name="radius"
                                                        id="radius"
                                                        onChange={createInputChangeHandler('radius')}
                                                        value={inputFormFields.radius}
                                                        autoComplete="5"
                                                        className="shadow-sm focus:ring-indigo-500 p-2 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="mt-6">
                                            <fieldset className="space-y-5">
                                                <label htmlFor="notifications" className="block text-sm font-medium text-gray-700">
                                                    Notifications
                                                </label>
                                                <div className="relative flex items-start">
                                                    <div className="flex items-center h-5">
                                                        <input
                                                            id="comments"
                                                            aria-describedby="comments-description"
                                                            name="comments"
                                                            type="checkbox"
                                                            checked={arrivedIsChecked}
                                                            onChange={updateArrivedCheck}
                                                            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                                                        />
                                                    </div>
                                                    <div className="ml-3 text-sm">
                                                        <label htmlFor="comments" className="font-medium text-gray-700">
                                                            Arrived
                                                        </label>
                                                        <p id="comments-description" className="text-gray-500">
                                                            Get notified when someones arrives.
                                                        </p>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="relative flex items-start">
                                                        <div className="flex items-center h-5">
                                                            <input
                                                                id="candidates"
                                                                checked={departedIsChecked}
                                                                onChange={updateDepartedCheck}
                                                                aria-describedby="candidates-description"
                                                                name="candidates"
                                                                type="checkbox"
                                                                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                                                            />
                                                        </div>
                                                        <div className="ml-3 text-sm">
                                                            <label htmlFor="candidates" className="font-medium text-gray-700">
                                                                Departure
                                                            </label>
                                                            <p id="candidates-description" className="text-gray-500">
                                                                Get notified when someone departs.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </fieldset>
                                        </div>

                                        <div>
                                            <label htmlFor="cover-photo" className="block text-sm font-medium my-4 text-gray-700 sm:mt-px sm:pt-2">
                                                Upload GeoJson
                                            </label>
                                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                                                <div className="max-w-lg flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                                                    <div className="space-y-1 text-center">
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            className="mx-auto h-12 w-12 text-gray-400"
                                                            fill="none"
                                                            viewBox="0 0 24 24"
                                                            stroke="currentColor">
                                                            <path
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                strokeWidth={2}
                                                                d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"
                                                            />
                                                        </svg>

                                                        <div className="flex text-sm text-gray-600">
                                                            <label
                                                                htmlFor="file-upload"
                                                                className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                                                                <span>Upload a file</span>
                                                                <input id="file-upload" name="file-upload" onChange={handleSingleFieldInput} type="file" className="sr-only" />
                                                            </label>
                                                            <p className="pl-1">or drag and drop</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                        <button
                            type="button"
                            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-400 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                            onClick={() => {
                                updateWaypoint()
                            }}>
                            Save
                        </button>
                        <button
                            type="button"
                            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                            onClick={() => {
                                WaypointStore.clearSelect()
                            }}>
                            Close
                        </button>
                    </div>
                </div>
            </div>
        )
    )
})
