import { action, makeAutoObservable } from 'mobx'
import { AlertItems, LogProps } from '../../interfaces'

export class LogStore {
    logs: LogProps[] = []
    alert: AlertItems[] = []
    fetchingData: boolean = false

    constructor() {
        makeAutoObservable(this)
    }

    @action appendNewLog(log: LogProps) {
        this.logs.unshift(log)
    }

    @action clearLogs() {
        this.logs = []
    }

    @action updateFetchingData() {
        this.fetchingData = !this.fetchingData
    }

    @action appendNewAlert(log: AlertItems) {
        this.alert = this.alert.concat(log)
    }

    @action clearAlerts() {
        this.alert = []
    }

    @action updateLogs(logs: LogProps[]) {
        this.logs = logs
    }

    @action concatLogs(logs: LogProps[]) {
        this.logs = this.logs.concat(logs)
    }
}

export default new LogStore()
