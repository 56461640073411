import { observer } from 'mobx-react'
import { PromiseValue } from 'type-fest'
import gql from 'graphql-tag'
import bourne from '@hapi/bourne'

import { Disclosure } from '@headlessui/react'

import 'react-date-range/dist/styles.css' // main css file
import 'react-date-range/dist/theme/default.css' // theme css file
import { endOfToday, format, getTime, parseISO, startOfYesterday } from 'date-fns'
import { DateRangePicker } from 'react-date-range'
import { useEffect, useRef, useState } from 'react'
import { useFela } from 'react-fela'
import memoize from 'lodash/memoize'
import debounce from 'lodash/debounce'
import { Icon } from '@iconify/react'
import omit from 'lodash/omit'
import { Map, Set } from 'immutable'
import { from, mergeMap, reduce, map } from 'rxjs'
import Fuse from 'fuse.js'
import alertTypes from './alertTypes'

import FleetStore from '../../../../../../store/FleetStore/FleetStore'
import { database } from '../../../../../../localdb'

import './styles.sass'
import { useQuery } from '@apollo/client'
import VehicleStore from '../../../../../../store/VehicleStore/VehicleStore'
import { VehicleProps } from '../../../../../../interfaces'
import { RUNNING_IN_PROD } from '../../../../../../environment'
import { autorun } from 'mobx'

const dateBreak = {
    minWidth: '1px',
    minHeight: '1px',
    alignSelf: 'stretch',
    margin: '4px',
}

const textArea = {
    minHeight: '2.5rem',
}

// const tableStyles = {}
const GET_LOGS = gql`
    query LogsQuery(
        $fleet: ID!
        $start: DateTime!
        $end: DateTime!
        $limit: Int = 30
        $offset: Int = 0
        $requiredTags: [String!] = null
        $barredTags: [String!] = null
        $oneOfTags: [String!] = null
    ) {
        fleet(slug: $fleet) {
            logs(start: $start, end: $end, limit: $limit, offset: $offset, requiredTags: $requiredTags, barredTags: $barredTags, oneOfTags: $oneOfTags) {
                when
                data
                fleet
                relatedEntities
                tags
                type
            }
        }
    }
`

const formatDate = memoize(date => format(date, 'PPPPpp'))

interface Log {
    id: string
    when: number
    fleet: string | null
    type: string
    tags: string[]
    data: any
    relatedEntities: { [k: string]: any }
}

function generateVehicleIdentifier(log: Log) {
    return [log.relatedEntities?.vehicle?.vehicle_number, log.relatedEntities?.vehicle?.registration].filter(v => !!v).join('/')
}

function getLogEntryDescription(log: Log) {
    let base = log.type?.replaceAll('_', ' ') ?? 'departed'
    switch (log.type) {
        case 'waypoint_event':
            return `${generateVehicleIdentifier(log)} ${log.tags.includes('arrived') ? 'arrived at' : 'departed from'} ${log.relatedEntities?.waypoint?.name}`
        case 'netstar_alert':
            return `${base}: ${alertTypes.get(log.data?.event_id) ?? `unknown alert type (${log.data?.event_id})`}`
        case 'received_location':
            return `${base} for ${generateVehicleIdentifier(log)}`
    }
    return base
}

function getLogEntryLocation(log: Log) {
    let res = log.data?.address
    if (res != null) {
        return (
            <a className="text-sm text-blue-300 underline" href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(res)}`}>
                At {res}
            </a>
        )
    }
    res = log.data?.coordinate
    if (res == null && 'latitude' in log.data && 'longitude' in log.data) {
        res = log.data
    }
    if (
        res == null &&
        log.relatedEntities?.vehicle?.position != null &&
        'latitude' in log.relatedEntities?.vehicle?.position &&
        'longitude' in log.relatedEntities?.vehicle?.position
    ) {
        res = log.relatedEntities?.vehicle?.position
    }
    if (res != null) {
        return (
            <a className="text-sm text-blue-300 underline" href={`https://www.google.com/maps/search/?api=1&query=${res.latitude},${res.longitude}`}>
                At {res.latitude},{res.longitude}
            </a>
        )
    }

    return res
}

export const logTypeIconMap = Map({
    location_received: 'heroicons-outline:location-marker',
    netstar_alert: 'ant-design:alert-twotone',
    waypoint_event: 'ic:twotone-mode-of-travel',
})

export const LogEntry = observer(function LogEntry({ item, onTagClicked }: { item: Log; onTagClicked: (tag: string) => void }) {
    return (
        <li className="py-4">
            <div className="divide-y divide-gray-200 px-8 py-2">
                <div className="flex space-x-3">
                    <Icon icon={logTypeIconMap.get(item.type) ?? 'heroicons-outline:location-marker'} />
                    <div className="flex-1 space-y-1">
                        <div className="flex items-center justify-between">
                            <h3 className="text-sm font-medium">{item.relatedEntities?.vehicle?.vehicle_number}</h3>
                            <a className="text-sm text-gray-500" onClick={() => console.log(item)}>
                                {format(new Date(item.when), 'eee dd MMM HH:mm')}
                            </a>
                        </div>

                        <p className="text-sm text-gray-500 capitalize">{getLogEntryDescription(item)}</p>
                        {getLogEntryLocation(item)}
                        <div className="flex flex-wrap mt-2">
                            {item.tags
                                .filter(d => !d.startsWith('env:') && !d.startsWith('netstar:') && !d.startsWith('ctrack:') && !d.startsWith('fleet:'))
                                .map(t => (
                                    <a
                                        className="inline-flex rounded-full items-center py-0.5 px-2.5 text-sm font-medium bg-indigo-100 text-indigo-700 mr-2 mb-1 cursor-pointer"
                                        key={t}
                                        onClick={() => onTagClicked?.(t)}>
                                        <span className="mb-0.5">{t}</span>
                                    </a>
                                ))}
                        </div>
                    </div>
                </div>
            </div>
        </li>
    )
})

export const tagStateIconMap = Map([
    [null, 'bx:bx-checkbox-square'],
    [undefined, 'bx:bx-checkbox-square'],
    [true, 'bx:bx-checkbox-checked'],
    [false, 'bx:bx-checkbox-minus'],
])
export const tagStateColorMap = Map([
    [null, 'text-current'],
    [undefined, 'text-current'],
    [true, 'text-green-500'],
    [false, 'text-red-500'],
])
const tagToggleTransitions = Map([
    [undefined, true],
    [null, true],
    [true, false],
    [false, null],
])
export const LogsView = observer(function RealtimeLogsViewComponent() {
    const [db, setDb] = useState<PromiseValue<typeof database>>()
    const [vehicleListFilter, setVehicleListFilter] = useState<string>('')

    const [dateRange, setDateRange] = useState([
        {
            startDate: startOfYesterday(),
            endDate: endOfToday(),
            key: 'selection',
        },
    ])

    const [logs, setLogs] = useState<any[]>([])
    const [textFilter, setTextFilter] = useState('')
    const [filteredLogs, setFilteredLogs] = useState<Fuse.FuseResult<Log>[]>([])
    const textFilterer = useRef(
        new Fuse<Log>([], {
            shouldSort: true,
            keys: ['relatedEntities.vehicle.vehicle_number', 'relatedEntities.vehicle.registration', 'tags'],
        })
    )
    useEffect(() => {
        textFilterer.current.setCollection(logs)
        setFilteredLogs(textFilterer.current.search(textFilter))
    }, [logs, textFilter])

    // useEffect(() => {
    //     graphqlFleetLogs?.fetchMore({
    //         variables: {
    //             fleet: FleetStore.fleet.key,
    //             start: dateRange[0].startDate,
    //             end: dateRange[0].endDate,
    //             offset: 0,
    //         },
    //     })
    // }, [dateRange])

    const [tagSearch, setTagSearch] = useState('')
    const [tags, setTags] = useState<Map<string, boolean | null | undefined>>(
        Map({
            // These function as an OR
            // put your default tags in here, for example uncomment the one below:
            waypoint_event: null,
            netstar_alert: null,
            received_location: false,
        })
    )
    const [selectedVehicles, setSelectedVehicles] = useState<Set<VehicleProps>>(Set([]))
    const [filteredTags, setFilteredTags] = useState<Fuse.FuseResult<string>[]>([])
    const tagFilterer = useRef(new Fuse<string>([], {}))
    useEffect(() => {
        tagFilterer.current.setCollection([...tags.keys()])
        setFilteredTags(tagFilterer.current.search(tagSearch))
    }, [tags, tagSearch])

    const [filters, setFilter] = useState({})

    const [advancedSearch, setAdvancedSearch] = useState('{}')
    const tryParse = useRef(
        debounce(s => {
            try {
                setFilter(bourne.parse(s))
            } catch (e) {}
        }, 400)
    )

    useEffect(() => {
        tryParse.current(advancedSearch)
    }, [advancedSearch])

    useEffect(() => {
        database.then(setDb)
        database.then(console.dir)
    }, [])

    const [fullyLoaded, setFullyLoaded] = useState(false)
    const [nextOffset, setNextOffset] = useState(0)
    const [offset, setOffset] = useState(0)
    const graphqlFleetLogs = useQuery(GET_LOGS, {
        variables: {
            fleet: FleetStore.fleet.key,
            start: dateRange[0].startDate,
            end: dateRange[0].endDate,
            offset: offset,

            requiredTags: [...selectedVehicles.map(v => `vehicle:${v.registration}`), RUNNING_IN_PROD ? 'env:production' : 'env:development'],
            barredTags: [...tags.filter(v => v === false).keys()],
            oneOfTags: [...tags.filter(v => v).keys()],
        },
    })

    const logItems: { item: Log }[] = textFilter.trim() === '' ? logs.map(item => ({ item })) : filteredLogs

    useEffect(
        () =>
            autorun(() => {
                if (db) {
                    const tagFilter = tags.filter(v => v)
                    const tagNotFilter = tags.filter(v => v === false)
                    const tagFilters = [
                        {
                            fleet: { $eq: FleetStore.fleet.key },
                            when: {
                                $lte: getTime(dateRange[0].endDate),
                                $gte: getTime(dateRange[0].startDate),
                            },
                        },
                        selectedVehicles.size === 0
                            ? false
                            : {
                                  tags: {
                                      $elemMatch: {
                                          $in: [...selectedVehicles.map(v => `vehicle:${v.registration}`)],
                                      },
                                  },
                              },
                        tagFilter.size === 0
                            ? false
                            : {
                                  tags: { $elemMatch: { $in: [...tagFilter.keys()] } },
                              },
                        tagNotFilter.size === 0
                            ? false
                            : {
                                  $not: { tags: { $elemMatch: { $in: [...tagNotFilter.keys()] } } },
                              },
                    ].filter(v => !!v)

                    const s = db.logs
                        .find({
                            selector: {
                                $and: tagFilters,
                            },
                            sort: [{ id: 'desc' }],
                        })
                        .where({ selector: filters })
                        .$.pipe(
                            mergeMap(l => {
                                return from(l).pipe(
                                    // @ts-ignore
                                    mergeMap(d => d.tags),
                                    reduce((acc, t) => acc.update(t, (v = null) => v), tags),
                                    map(t => ({
                                        logs: l,
                                        tags: t,
                                    }))
                                )
                            })
                        )
                        .subscribe(d => {
                            if (!d.tags.equals(tags)) {
                                setTags(d.tags.sortBy(v => null == v))
                            }
                            setLogs(d.logs)
                        })
                    return () => s.unsubscribe()
                } else {
                    // setFilter({})
                }
                return undefined
            }),
        [dateRange, db, filters, tags, selectedVehicles]
    )

    useEffect(() => {
        if (db != null && graphqlFleetLogs.data != null) {
            db.logs
                .bulkInsert(
                    graphqlFleetLogs.data.fleet.logs.map(d => ({
                        ...omit(d, ['__typename']),
                        when: getTime(parseISO(d.when)),
                        data: d.data != null ? bourne.parse(d.data) : null,
                        relatedEntities: d.relatedEntities != null ? bourne.parse(d.relatedEntities) : {},
                    }))
                )
                .then(() => {
                    if (
                        graphqlFleetLogs.data.fleet.logs.length < 30 &&
                        graphqlFleetLogs.variables?.fleet === FleetStore.fleet.key &&
                        graphqlFleetLogs.variables?.start === dateRange[0].startDate &&
                        graphqlFleetLogs.variables?.end === dateRange[0].endDate
                    ) {
                        setFullyLoaded(true)
                        return
                    }
                    setFullyLoaded(false)
                    return graphqlFleetLogs
                        ?.fetchMore({
                            variables: {
                                fleet: FleetStore.fleet.key,
                                start: dateRange[0].startDate,
                                end: dateRange[0].endDate,
                                offset: graphqlFleetLogs.variables?.offset + graphqlFleetLogs.data.fleet.logs.length,
                            },
                        })
                        ?.then(() => setNextOffset(graphqlFleetLogs.variables?.offset + graphqlFleetLogs.data.fleet.logs.length))
                })
        }
    }, [db, dateRange, graphqlFleetLogs])

    const { css } = useFela()

    return (
        <div className="flex-1 relative z-0 flex overflow-hidden">
            <aside className="hidden relative xl:order-first xl:flex xl:flex-col flex-shrink-0 w-96 border-r border-gray-200">
                <nav className="h-full bg-white overflow-y-auto" aria-label="Directory">
                    <div className="relative">
                        <div className="z-10 sticky top-0 border-t border-b border-gray-200 bg-gray-50 px-6 py-3 text-sm font-medium text-black">
                            <input
                                type="text"
                                name="desktop-trip-name"
                                id="desktop-trip-name"
                                className="mt-1 border block w-full pl-3 pr-2 py-1 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-md text-black rounded-md"
                                value={vehicleListFilter}
                                onChange={e => {
                                    setVehicleListFilter(e.target.value)
                                }}
                                placeholder="Vehicle filter..."
                            />
                        </div>
                        <fieldset className="relative z-0 divide-y divide-gray-200">
                            <legend className="sr-only">Vehicles</legend>
                            {FleetStore.vehicles
                                .slice()
                                .filter(v => vehicleListFilter.trim() === '' || `${v.registration}`.includes(vehicleListFilter) || v.vehicle_number?.includes(vehicleListFilter))
                                .sort((a, b) => a.vehicle_number.localeCompare(b.vehicle_number))
                                .map(value => {
                                    return (
                                        <div
                                            className="relative flex items-start cursor-pointer items-center px-2 py-1 hover:bg-gray-50"
                                            key={value.key}
                                            onMouseEnter={() => VehicleStore.hover(value)}
                                            onMouseLeave={() => VehicleStore.clearHover()}
                                            onClick={() => setSelectedVehicles(selectedVehicles.has(value) ? selectedVehicles.remove(value) : selectedVehicles.add(value))}>
                                            <div className="flex items-center h-5">
                                                <input
                                                    id={`vehicle-key-${value.key}`}
                                                    aria-describedby="comments-description"
                                                    name={`vehicle-key-${value.key}`}
                                                    type="checkbox"
                                                    readOnly
                                                    checked={selectedVehicles.has(value)}
                                                    className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                                                />
                                            </div>
                                            <div className="ml-3 text-sm">
                                                <label htmlFor={`vehicle-key-${value.key}`} className="font-medium text-gray-700">
                                                    {value.vehicle_number}
                                                </label>
                                                <p className="text-gray-500">{value.registration}</p>
                                            </div>
                                        </div>
                                    )
                                })}
                        </fieldset>
                        <div className="max-w-3xl mx-auto divide-y-2 sticky px-4 pb-4 border-t border-gray-200 bottom-0 left-0 bg-white divide-gray-200">
                            <div className="max-w-3xl mx-auto divide-y-2 divide-gray-200" style={{ maxHeight: '80vh' }}>
                                <dl className="mt-6 space-y-6 divide-y divide-gray-200">
                                    <Disclosure as="div">
                                        {({ open }) => (
                                            <>
                                                <Disclosure.Button className="text-lg text-left w-full flex justify-between items-start text-gray-400">
                                                    <span className="font-medium text-gray-900">Advanced filters</span>
                                                    <span className="ml-6 h-7 flex items-center">
                                                        <Icon
                                                            icon="heroicons-outline:chevron-down"
                                                            className={`h-6 w-6 transform transition-transform ${open ? '-rotate-180' : 'rotate-0'}`}
                                                            aria-hidden="true"
                                                        />
                                                    </span>
                                                </Disclosure.Button>
                                                <Disclosure.Panel as="dd" className="mt-2 pr-12">
                                                    <div className="sm:col-span-6">
                                                        <label htmlFor="desktop-trip-name" className="block text-sm font-medium text-gray-700">
                                                            Fuzzy search
                                                        </label>
                                                        <input
                                                            type="text"
                                                            name="desktop-trip-name"
                                                            id="desktop-trip-name"
                                                            className="mt-1 border block w-full pl-3 pr-2 py-1 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-md text-black rounded-md"
                                                            value={textFilter}
                                                            onChange={e => {
                                                                setTextFilter(e.target.value)
                                                            }}
                                                        />
                                                        <label htmlFor="tag-search" className="block text-sm font-medium text-gray-700 mt-2">
                                                            Tags
                                                        </label>
                                                        <div className="mt-1 flex rounded-md shadow-sm">
                                                            <div className="relative flex items-stretch flex-grow focus-within:z-10">
                                                                <input
                                                                    type="text"
                                                                    name="tag-search"
                                                                    id="tag-search"
                                                                    value={tagSearch}
                                                                    onChange={e => setTagSearch(e.target.value)}
                                                                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded-none rounded-l-md pl-3 sm:text-sm border-gray-300"
                                                                    placeholder="Tag filter"
                                                                />
                                                            </div>
                                                            <button
                                                                type="button"
                                                                onClick={() => setTagSearch('')}
                                                                className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">
                                                                Clear
                                                            </button>
                                                        </div>
                                                        <div className="flex flex-wrap mt-2 justify-center">
                                                            {[
                                                                ...filteredTags,
                                                                ...(tagSearch.trim() === ''
                                                                    ? [...tags.keys()]
                                                                          .filter(
                                                                              d =>
                                                                                  (!d.startsWith('env:') &&
                                                                                      !d.startsWith('vehicle:') &&
                                                                                      !d.startsWith('vehicle_number:') &&
                                                                                      !d.startsWith('ctrack:') &&
                                                                                      !d.startsWith('netstar:') &&
                                                                                      !d.startsWith('fleet:')) ||
                                                                                  tags.get(d) != null
                                                                          )
                                                                          .map(item => ({ item }))
                                                                    : []),
                                                            ].map(t => {
                                                                const state = tags.get(t.item)
                                                                return (
                                                                    <span
                                                                        className="inline-flex rounded-full items-center py-0.5 pl-2.5 pr-1 text-sm font-medium bg-indigo-100 text-indigo-700 mr-2 mb-1 cursor-pointer "
                                                                        key={t.item}
                                                                        onClick={() => setTags(tags.set(t.item, tagToggleTransitions.get(state)).sortBy(v => null == v))}>
                                                                        <span className="mb-0.5">{t.item}</span>
                                                                        <Icon
                                                                            icon={tagStateIconMap.get(state) ?? 'bx:bx-checkbox-square'}
                                                                            className={`h-6 w-6 transition-colors ${tagStateColorMap.get(state) ?? 'text-current'}`}
                                                                        />
                                                                    </span>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                    <div className="sm:col-span-6">
                                                        <div className="sm:col-span-6">
                                                            <label htmlFor="advancedFilter" className="block text-sm font-medium text-gray-700">
                                                                Advanced Filter (Use at own risk)
                                                            </label>
                                                            <div className="mt-1">
                                                                <textarea
                                                                    id="advancedFilter"
                                                                    name="advancedFilter"
                                                                    value={advancedSearch}
                                                                    rows={3}
                                                                    className={`${css(
                                                                        textArea
                                                                    )} shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md`}
                                                                    onChange={e => {
                                                                        setAdvancedSearch(e.target.value)
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Disclosure.Panel>
                                            </>
                                        )}
                                    </Disclosure>
                                </dl>
                            </div>
                        </div>
                    </div>
                </nav>
            </aside>
            <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none xl:order-last">
                <div className="z-10 sticky top-0 border-t border-b border-gray-200 bg-gray-50 px-6 py-3 text-sm font-medium text-black">
                    <div className="sm:col-span-6 date-range-container">
                        <div className="absolute z-10 date-range-element">
                            <DateRangePicker
                                onChange={item => setDateRange([item.selection])}
                                showSelectionPreview={true}
                                moveRangeOnFirstSelection={false}
                                months={2}
                                ranges={dateRange}
                                direction="horizontal"
                            />
                        </div>
                        <button
                            type="button"
                            className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                            <span>{formatDate(dateRange[0].startDate)}</span>
                            <span className={css(dateBreak) + ' bg-gray-300'} />
                            <span>{formatDate(dateRange[0].endDate)}</span>
                        </button>
                    </div>
                </div>
                <div>
                    <ul className="divide-y divide-gray-200">
                        {logItems.map(({ item }, index) => (
                            <LogEntry key={index} item={item} onTagClicked={tag => setTags(tags.set(tag, true).sortBy(v => null == v))} />
                        ))}
                        <li className="flex py-4 px-2">
                            {fullyLoaded ? (
                                <p className="px-2 py-1.5">All logs are loaded</p>
                            ) : (
                                <button
                                    className="inline-flex flex-1 justify-center items-center px-2 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    type="button"
                                    onClick={() => setOffset(nextOffset)}>
                                    Load more
                                </button>
                            )}
                        </li>
                    </ul>
                </div>
            </main>
        </div>
    )
})

export const RealtimeLogsView = observer(function LogsWrapper() {
    return FleetStore.fleet.key ? (
        <LogsView />
    ) : (
        <div className="w-full h-full fixed block bottom-0 left-0 bg-gray-600 opacity-75 z-100">
            <svg className="animate-spin -ml-1 mr-3 h-20 w-20 text-white absolute top-1/2 left-1/2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                />
            </svg>
        </div>
    )
})

export default RealtimeLogsView
