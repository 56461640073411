import { observer } from 'mobx-react'
import { useParams } from 'react-router-dom'
import { Fragment, useEffect, useState } from 'react'
import { apiFetchSelectedFleet } from '../../api'
import { SelectFleet } from './views/SelectFleet/SelectFleet'
import FleetStore from '../../store/FleetStore/FleetStore'
import NotificationStore from '../../store/NotificationStore/NotificationStore'
import FleetLogo from '../../assets/gmlogo.png'
import { goHistory } from '../../index'

import { Dialog, Disclosure, Transition } from '@headlessui/react'
import { RealtimeVehiclesView } from './views/Realtime/views/Vehicles/Vehicles'
import { PlanningWaypointsView } from './views/Planning/views/Waypoints/Waypoints'
import { XIcon } from '@heroicons/react/solid'
import { MapIcon, FolderIcon, CogIcon, MenuIcon, CheckCircleIcon, ExclamationCircleIcon, DocumentReportIcon } from '@heroicons/react/outline'
import { RealtimeLogsView } from './views/Realtime/views/Log/Logs'
import { RealtimeDutiesView } from './views/Realtime/views/Duties/Duties'
import { PlanningDutiesView } from './views/Planning/views/Duties/Duties'
import { PlanningTripsView } from './views/Planning/views/Trips/Trips'
import { OperationsDispatchView } from './views/Operations/views/Dispatch/Dispatch'
import { ReportsDutiesView } from './views/Reports/views/Duties/Duties'

export const Fleet = observer(function FleetView(this: any) {
    const { slug, category, view } = useParams()

    const [sidebarOpen, setSidebarOpen] = useState(false)

    const navigation = [
        {
            name: 'Monitoring',
            category: 'monitoring',
            icon: MapIcon,
            current: false,
            children: [
                { name: 'vehicles', href: `/fleet/${FleetStore.fleet.slug}/monitoring/vehicles` },
                { name: 'duties', href: `/fleet/${FleetStore.fleet.slug}/monitoring/duties` },
                { name: 'logs', href: `/fleet/${FleetStore.fleet.slug}/monitoring/logs` },
            ],
        },
        {
            name: 'Planning',
            category: 'planning',
            icon: FolderIcon,
            current: false,
            children: [
                { name: 'waypoints', href: `/fleet/${FleetStore.fleet.slug}/planning/waypoints` },
                { name: 'trips', href: `/fleet/${FleetStore.fleet.slug}/planning/trips` },
                // { name: 'duties', href: `/fleet/${FleetStore.fleet.slug}/planning/duties` },
            ],
        },
        {
            name: 'Operations',
            category: 'operations',
            icon: CogIcon,
            current: false,
            children: [{ name: 'dispatch', href: `/fleet/${FleetStore.fleet.slug}/operations/dispatch` }],
        },
        {
            name: 'Reports',
            category: 'reports',
            icon: DocumentReportIcon,
            current: false,
            children: [{ name: 'duties', href: `/fleet/${FleetStore.fleet.slug}/reports/duties` }],
        },
    ]

    function classNames(...classes: any) {
        return classes.filter(Boolean).join(' ')
    }

    useEffect(() => {
        if (typeof FleetStore.fleet.key === 'undefined' && slug) {
            apiFetchSelectedFleet(slug)
        }
    }, [])

    return (
        <div className="h-screen flex overflow-hidden bg-white">
            <Transition.Root show={sidebarOpen} as={Fragment}>
                <Dialog as="div" className="fixed inset-0 flex z-40 lg:hidden" onClose={setSidebarOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0">
                        <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
                    </Transition.Child>
                    <Transition.Child
                        as={Fragment}
                        enter="transition ease-in-out duration-300 transform"
                        enterFrom="-translate-x-full"
                        enterTo="translate-x-0"
                        leave="transition ease-in-out duration-300 transform"
                        leaveFrom="translate-x-0"
                        leaveTo="-translate-x-full">
                        <div className="relative flex-1 flex flex-col max-w-xs w-full bg-white focus:outline-none">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-in-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in-out duration-300"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0">
                                <div className="absolute top-0 right-0 -mr-12 pt-2">
                                    <button
                                        type="button"
                                        className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                                        onClick={() => setSidebarOpen(false)}>
                                        <span className="sr-only">Close sidebar</span>
                                        <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                    </button>
                                </div>
                            </Transition.Child>
                            <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                                <div className="flex-shrink-0 flex items-center px-4">
                                    <img alt="logo" className="main-logo" src={FleetLogo} />
                                </div>
                                <nav aria-label="Sidebar" className="mt-5">
                                    <div className="px-2 space-y-1">
                                        {navigation.map(item =>
                                            !item.children ? (
                                                <div key={item.name}>
                                                    <a
                                                        href="#"
                                                        className={classNames(
                                                            item.current ? 'bg-gray-100 text-gray-900' : 'bg-white text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                                                            'group w-full flex items-center pl-2 py-2 text-sm font-medium rounded-md'
                                                        )}>
                                                        <item.icon
                                                            className={classNames(
                                                                item.current ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
                                                                'mr-3 flex-shrink-0 h-6 w-6'
                                                            )}
                                                            aria-hidden="true"
                                                        />
                                                        {item.name}
                                                    </a>
                                                </div>
                                            ) : (
                                                <Disclosure as="div" key={item.name} defaultOpen={category === 'monitoring'} className="space-y-1">
                                                    {({ open }) => (
                                                        <>
                                                            <Disclosure.Button
                                                                className={classNames(
                                                                    item.current ? 'bg-gray-100 text-gray-900' : 'bg-white text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                                                                    'group w-full flex items-center pl-2 pr-1 py-2 text-left text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500'
                                                                )}>
                                                                <item.icon className="mr-3 flex-shrink-0 h-6 w-6 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                                                                <span className="flex-1">{item.name}</span>
                                                                <svg
                                                                    className={classNames(
                                                                        open ? 'text-gray-400 rotate-90' : 'text-gray-300',
                                                                        'ml-3 flex-shrink-0 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150'
                                                                    )}
                                                                    viewBox="0 0 20 20"
                                                                    aria-hidden="true">
                                                                    <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
                                                                </svg>
                                                            </Disclosure.Button>
                                                            <Disclosure.Panel className="space-y-1">
                                                                {item.children.map(subItem => (
                                                                    <button
                                                                        key={subItem.name}
                                                                        onClick={() => {
                                                                            goHistory.push(subItem.href)
                                                                        }}
                                                                        className="group w-full flex items-center pl-11 pr-2 py-2 text-sm font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50">
                                                                        {subItem.name}
                                                                    </button>
                                                                ))}
                                                            </Disclosure.Panel>
                                                        </>
                                                    )}
                                                </Disclosure>
                                            )
                                        )}
                                    </div>
                                </nav>
                            </div>
                            <div className="flex-shrink-0 flex border-t border-gray-200 p-4">
                                <button
                                    onClick={() => {
                                        goHistory.push('/fleet/select')
                                    }}
                                    className="flex-shrink-0 group block">
                                    <div className="flex items-center">
                                        <div className="ml-3">
                                            <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">Fleet:</p>
                                            <p className="text-xs font-medium text-gray-500 group-hover:text-gray-700">
                                                {FleetStore.fleet.key ? FleetStore.fleet.name : 'Not selected'}
                                            </p>
                                        </div>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </Transition.Child>
                    <div className="flex-shrink-0 w-14" aria-hidden="true">
                        {/* Force sidebar to shrink to fit close icon */}
                    </div>
                </Dialog>
            </Transition.Root>

            {/* Static sidebar for desktop */}
            {FleetStore.fleet.key && (
                <div className="hidden lg:flex lg:flex-shrink-0">
                    <div className="flex flex-col w-64">
                        {/* Sidebar component, swap this element with another sidebar if you like */}
                        <div className="flex-1 flex flex-col min-h-0 border-r border-gray-200 bg-gray-100">
                            <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
                                <div className="flex items-center flex-shrink-0 px-4">
                                    <img alt="logo" className="main-logo" src={FleetLogo} />
                                </div>
                                <nav className="mt-5 flex-1" aria-label="Sidebar">
                                    <div className="px-2 space-y-1">
                                        {navigation.map(item =>
                                            !item.children ? (
                                                <div key={item.name}>
                                                    <a
                                                        href="#"
                                                        className={classNames(
                                                            item.current ? 'bg-gray-100 text-gray-900' : 'bg-white text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                                                            'group w-full flex items-center pl-2 py-2 text-sm font-medium rounded-md'
                                                        )}>
                                                        <item.icon
                                                            className={classNames(
                                                                item.current ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
                                                                'mr-3 flex-shrink-0 h-6 w-6'
                                                            )}
                                                            aria-hidden="true"
                                                        />
                                                        {item.name}
                                                    </a>
                                                </div>
                                            ) : (
                                                <Disclosure as="div" key={item.name} defaultOpen={category === item.category} className="space-y-1">
                                                    {({ open }) => (
                                                        <>
                                                            <Disclosure.Button
                                                                className={classNames(
                                                                    item.current ? 'bg-gray-100 text-gray-900' : 'bg-white text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                                                                    'group w-full flex items-center pl-2 pr-1 py-2 text-left text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500'
                                                                )}>
                                                                <item.icon className="mr-3 flex-shrink-0 h-6 w-6 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                                                                <span className="flex-1">{item.name}</span>
                                                                <svg
                                                                    className={classNames(
                                                                        open ? 'text-gray-400 rotate-90' : 'text-gray-300',
                                                                        'ml-3 flex-shrink-0 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150'
                                                                    )}
                                                                    viewBox="0 0 20 20"
                                                                    aria-hidden="true">
                                                                    <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
                                                                </svg>
                                                            </Disclosure.Button>
                                                            <Disclosure.Panel className="space-y-1">
                                                                {item.children.map(subItem => (
                                                                    <button
                                                                        key={subItem.name}
                                                                        onClick={() => {
                                                                            goHistory.push(subItem.href)
                                                                        }}
                                                                        className={`group w-full flex items-center pl-11 pr-2 py-2 text-sm font-medium rounded-md hover:bg-green-200 hover:text-white ${
                                                                            view === subItem.name && category === item.category ? 'bg-green-400 text-white' : 'text-gray-600'
                                                                        }`}>
                                                                        {subItem.name}
                                                                    </button>
                                                                ))}
                                                            </Disclosure.Panel>
                                                        </>
                                                    )}
                                                </Disclosure>
                                            )
                                        )}
                                    </div>
                                </nav>
                            </div>
                            <div className="flex-shrink-0 flex border-t border-gray-200 p-4">
                                <button
                                    onClick={() => {
                                        goHistory.push('fleet/select')
                                    }}
                                    className="flex-shrink-0 w-full group block">
                                    <div className="flex items-center">
                                        <div className="ml-3">
                                            <p className="text-sm text-left font-medium text-gray-700 group-hover:text-gray-900">Fleet:</p>
                                            <p className="text-xs font-medium text-gray-500 group-hover:text-gray-700">
                                                {FleetStore.fleet.key ? FleetStore.fleet.name : 'Not selected'}
                                            </p>
                                        </div>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div className="flex flex-col min-w-0 flex-1 overflow-hidden">
                {FleetStore.fleet.key && (
                    <div className="lg:hidden">
                        <div className="flex items-center justify-between bg-gray-50 border-b border-gray-200 px-4 py-1.5">
                            <div>
                                <img alt="logo" className="main-logo" src={FleetLogo} />
                            </div>
                            <div>
                                <button
                                    type="button"
                                    className="-mr-3 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900"
                                    onClick={() => setSidebarOpen(true)}>
                                    <span className="sr-only">Open sidebar</span>
                                    <MenuIcon className="h-6 w-6" aria-hidden="true" />
                                </button>
                            </div>
                        </div>
                    </div>
                )}
                <div className="flex-1 relative z-0 flex overflow-hidden">
                    {(() => {
                        switch (category) {
                            case 'monitoring':
                                switch (view) {
                                    case 'vehicles':
                                        return <RealtimeVehiclesView />
                                    case 'logs':
                                        return <RealtimeLogsView />
                                    case 'duties':
                                        return <RealtimeDutiesView />
                                    default:
                                        return <SelectFleet />
                                }

                            case 'planning':
                                switch (view) {
                                    case 'waypoints':
                                        return <PlanningWaypointsView />
                                    case 'trips':
                                        return <PlanningTripsView />
                                    case 'duties':
                                        return <PlanningDutiesView />
                                    default:
                                        return <SelectFleet />
                                }

                            case 'operations':
                                switch (view) {
                                    case 'dispatch':
                                        return <OperationsDispatchView />
                                    default:
                                        return <SelectFleet />
                                }

                            case 'reports':
                                switch (view) {
                                    case 'duties':
                                        return <ReportsDutiesView />
                                    default:
                                        return <SelectFleet />
                                }
                            default:
                                return <SelectFleet />
                        }
                    })()}
                </div>
            </div>
            {
                <div aria-live="assertive" className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start">
                    <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
                        {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
                        <Transition
                            show={NotificationStore.showToast}
                            as={Fragment}
                            enter="transform ease-out duration-300 transition"
                            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0">
                            <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
                                <div className="p-4">
                                    <div className="flex items-start">
                                        <div className="flex-shrink-0">
                                            {NotificationStore.successfulToast && <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />}
                                            {!NotificationStore.successfulToast && <ExclamationCircleIcon className="h-6 w-6 text-yellow-400" aria-hidden="true" />}
                                        </div>
                                        <div className="ml-3 w-0 flex-1 pt-0.5">
                                            {NotificationStore.successfulToast && <p className="text-sm font-medium text-gray-900">Successful!</p>}
                                            {!NotificationStore.successfulToast && <p className="text-sm font-medium text-gray-900">Warning!</p>}

                                            <p className="mt-1 text-sm text-gray-500">{NotificationStore.toastText}</p>
                                        </div>
                                        <div className="ml-4 flex-shrink-0 flex">
                                            <button
                                                className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                onClick={() => {
                                                    NotificationStore.setToast('', false, true)
                                                }}>
                                                <span className="sr-only">Close</span>
                                                <XIcon className="h-5 w-5" aria-hidden="true" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Transition>
                    </div>
                </div>
            }
        </div>
    )
})
