import { action, makeAutoObservable } from 'mobx'
import { UserProps } from '../../interfaces'

export class UserStore {
    user: UserProps = {role: '', fleet: ['']}

    constructor() {
        makeAutoObservable(this)
    }

    @action updateRole(user: any) {
        this.user.role = user.role
        this.user.fleet = user.fleet
    }
}

export default new UserStore()
