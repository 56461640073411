import { observer } from 'mobx-react'
import { useEffect, useState } from 'react'
import FleetStore from '../../../../../../../../store/FleetStore/FleetStore'
import ReactMapGL, { Layer, Marker, Source, Popup, NavigationControl } from 'react-map-gl'
import { FaMapMarkerAlt } from '@react-icons/all-files/fa/FaMapMarkerAlt'
import WaypointMapStore from '../../../../../../../../store/WaypointMapStore/WaypointMapStore'

export const StopsMap = observer(function StopsMapComponent() {
    const [showPopup, setPopup] = useState({
        show: false,
        longitude: 0.0,
        latitude: 0.0,
        ref: '',
    })

    const mapStyle = {
        width: '100%',
        height: '98vh',
    }

    const navControlStyle = {
        right: 5,
        bottom: 25,
    }

    useEffect(() => {
        WaypointMapStore.generateGeoJson()
        // WaypointMapStore.updateViewport({
        //     latitude: FleetStore.fleet.latitude,
        //     longitude: FleetStore.fleet.longitude,
        //     zoom: FleetStore.fleet.zoom,
        // })
    }, [])

    return (
        FleetStore.vehicles && (
            <div>
                <ReactMapGL
                    dragRotate={false}
                    mapboxApiAccessToken={'pk.eyJ1IjoiYWZyb2xhYnNyaWNoYXJkIiwiYSI6ImNrZndsM2NneDFtM3gzMnBkOXNmdWlmYjQifQ.hxu87TsIwxtUwlU6iX7Q-w'}
                    mapStyle={'mapbox://styles/mapbox/light-v9?optimize=true'}
                    minZoom={3}
                    {...WaypointMapStore.viewport}
                    {...mapStyle}
                    onViewportChange={v => WaypointMapStore.updateViewport(v)}>
                    <NavigationControl showCompass={false} style={navControlStyle} />
                    <Source id={'waypoint-polygons'} type={'geojson'} data={WaypointMapStore.geojson} />

                    <Layer
                        id={'waypoint-layer'}
                        type={'fill'}
                        source={'waypoint-polygons'}
                        paint={{
                            'fill-color': '#8DD8FF',
                            'fill-opacity': 0.2,
                            'fill-outline-color': '#69ccff',
                        }}
                    />

                    {FleetStore.waypoints.map(value => {
                        return (
                            <Marker key={value.key} latitude={value.latitude} longitude={value.longitude}>
                                <FaMapMarkerAlt
                                    className={'stop-marker'}
                                    onMouseOver={() =>
                                        setPopup({
                                            show: true,
                                            latitude: value.latitude,
                                            longitude: value.longitude,
                                            ref: value.name,
                                        })
                                    }
                                    onMouseLeave={() =>
                                        setPopup({
                                            show: false,
                                            latitude: value.latitude,
                                            longitude: value.longitude,
                                            ref: value.name,
                                        })
                                    }
                                />
                            </Marker>
                        )
                    })}
                    {showPopup.show && (
                        <Popup latitude={showPopup.latitude} longitude={showPopup.longitude} closeButton={false}>
                            <div className={'stop-marker-name'}>{showPopup.ref}</div>
                        </Popup>
                    )}
                </ReactMapGL>
            </div>
        )
    )
})
