import { observer } from 'mobx-react'
import { useState } from 'react'
import FleetStore from '../../../../../../../../store/FleetStore/FleetStore'
import VehicleStore from '../../../../../../../../store/VehicleStore/VehicleStore'

export const PlanningDutiesAside = observer(function PlanningDutiesAsideComponent() {

    const [filteredVehicles, setFilteredVehicles] = useState(FleetStore.vehicles)

    const updateVehicles = (vehicles) => {
        setFilteredVehicles(vehicles)
    }

    const handleSearch = (e) => {
        let currentList: any = []
        let newList: any

        if (e.target.value !== '') {
            currentList = FleetStore.vehicles
            newList = currentList.filter(item => {
                const lc = item.vehicle_number.toLowerCase()
                const filter = e.target.value.toLowerCase()
                return lc.includes(filter)
            })
        } else {
            newList = FleetStore.vehicles
        }
        updateVehicles(newList)
    }

    return FleetStore.vehicles && (
        <aside
            className="hidden relative xl:order-first xl:flex xl:flex-col flex-shrink-0 w-96 border-r border-gray-200">
            <nav className="h-full bg-white overflow-y-auto" aria-label="Directory">
                <div className="relative">
                    <div
                        className="z-10 sticky top-0 border-t border-b border-gray-200 bg-gray-50 px-6 py-3 text-sm font-medium text-black">
                        <input type="text" name="desktop-trip-name" id="desktop-trip-name"
                               className="mt-1 border block w-full pl-3 pr-2 py-1 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-md text-black rounded-md"
                               onChange={handleSearch}
                               placeholder="Search vehicles..."/>


                    </div>
                    <ul role="list" className="relative z-0 divide-y divide-gray-200">
                        {filteredVehicles
                            .slice()
                            .sort((a, b) => a.vehicle_number.localeCompare(b.vehicle_number))
                            .map(value => {
                                return (
                                    <li key={value.key}
                                        onClick={() => VehicleStore.selectForDispatch(value)}>
                                        <div
                                            className={`transition duration-500 ease-in-out block cursor-pointer hover:bg-gray-50 text-black hover:text-white hover:bg-green-400 ${value.duty ? 'bg-yellow-100' : ''} ${VehicleStore.selectedForDispatch && VehicleStore.selectedForDispatch.key === value.key ? 'bg-green-400 text-white' : 'bg-white' }`}>
                                            <div className="px-4 py-4 sm:px-6">
                                                <div className="flex items-center justify-between">
                                                    <p className="text-sm font-medium truncate">
                                                        {value.vehicle_number}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                )
                            })}
                    </ul>
                </div>
            </nav>
        </aside>
    )
})
