import { Component } from 'react'
import { apiFetchFleets, apiFetchUsers } from '../../api'
import { CCol, CContainer, CDataTable, CNav, CNavItem, CNavLink, CRow, CTabContent, CTabPane, CTabs } from '@coreui/react'
import { inject, observer } from 'mobx-react'
import { UsersStore } from '../../store/UsersStore/UsersStore'
import UseCreateFleet from './components/Forms/UseCreateFleet'
import UseCreateUser from './components/Forms/UseCreateUser'
import { FleetStore } from '../../store/FleetStore/FleetStore'

interface AdminProps {
    usersStore: UsersStore
    fleetStore: FleetStore
}

@inject('usersStore', 'fleetStore')
@observer
class Admin extends Component<AdminProps> {
    componentWillMount(): void {
        apiFetchFleets()
    }

    componentDidMount(): void {
        apiFetchUsers()
    }

    render() {
        const fields = [
            { key: 'email', _style: { width: '40%' } },
            { key: 'role', _style: { width: '20%' } },
            { key: 'fleet', _style: { width: '20%' } },
        ]

        return (
            <div>
                <CTabs activeTab="vehicles">
                    <CNav variant="tabs">
                        <CNavItem>
                            <CNavLink data-tab="users">Users</CNavLink>
                        </CNavItem>
                        <CNavItem>
                            <CNavLink data-tab="fleets">Fleets</CNavLink>
                        </CNavItem>
                    </CNav>
                    <CTabContent>
                        <CTabPane data-tab="users">
                            <CContainer>
                                <CRow>
                                    <CCol lg="5">
                                        <div className="form-box">
                                            <UseCreateUser />
                                        </div>
                                    </CCol>
                                    <CCol lg="7" className="admin-table">
                                        <CDataTable
                                            items={this.props.usersStore.users}
                                            fields={fields}
                                            columnFilter={true}
                                            tableFilter={true}
                                            footer={true}
                                            itemsPerPageSelect={true}
                                            itemsPerPage={20}
                                            hover={true}
                                            sorter={true}
                                            pagination={true}
                                        />
                                    </CCol>
                                </CRow>
                            </CContainer>
                        </CTabPane>
                    </CTabContent>
                    <CTabContent>
                        <CTabPane data-tab="fleets">
                            <CContainer>
                                <CRow>
                                    <CCol lg="5">
                                        <div className="form-box">
                                            <UseCreateFleet />
                                        </div>
                                    </CCol>
                                    <CCol lg="7" className="admin-table">
                                        {this.props.fleetStore.fleets.map(value => {
                                            return <p key={value.key}>{value.name}</p>
                                        })}
                                    </CCol>
                                </CRow>
                            </CContainer>
                        </CTabPane>
                    </CTabContent>
                </CTabs>
            </div>
        )
    }
}

export default Admin
