import { makeAutoObservable } from 'mobx'
import { LogProps, VehicleProps } from '../../interfaces'
import LogStore from '../LogStore/LogStore'

export class VehicleStore {
    fleet: VehicleProps[] = []
    unlinked: VehicleProps[] = []
    selected: string = 'unset'
    selectedName: string = 'unset'

    selectedVehicle: any = null
    selectedForDispatch: any = null
    vehicleHovered: any = null

    mapChecked: boolean = true
    hideVehicles: boolean = false

    constructor() {
        makeAutoObservable(this)
    }

    setMapChecked(bool: boolean) {
        this.mapChecked = bool
    }

    hideVehicleList(bool: boolean) {
        this.hideVehicles = bool
    }

    updateVehicles(vehicles: any, unlinked: any) {
        this.fleet = vehicles
        this.unlinked = unlinked
    }

    lookForLocationUpdate(log: LogProps) {
        // @ts-ignore
        if (('ids' in log && typeof log.data_object.position !== 'undefined') || typeof log.data_object.coordinate !== 'undefined') {
            if (this.selected === 'unset') {
                return
            }

            let lat, lon

            if ('position' in log.data_object) {
                lat = log.data_object.position.latitude
                lon = log.data_object.position.longitude
            } else {
                lat = log.data_object.coordinate.latitude
                lon = log.data_object.coordinate.longitude
            }
            const vehicle = this.fleet[this.selected].find(o => o.registration === log.registration)

            if (typeof vehicle !== 'undefined') {
                LogStore.appendNewLog(log)

                vehicle.is_ignition_on = log.data_object.is_ignition_on
                vehicle.position.latitude = lat
                vehicle.position.longitude = lon
                vehicle.heading = log.data_object.heading
                vehicle.updated_at = new Date()
            }
        }
    }

    selectFleet(fleet: string, fleetName: string) {
        this.selected = fleet
        this.selectedName = fleetName
    }

    hover(vehicle: any) {
        this.vehicleHovered = vehicle
    }

    clearHover() {
        this.vehicleHovered = null
    }

    select(vehicle: any) {
        this.selectedVehicle = vehicle
    }

    selectForDispatch(vehicle: any) {
        this.selectedForDispatch = vehicle
    }

    clearSelect() {
        this.selectedVehicle = null
    }
}

export default new VehicleStore()
