import { observer } from 'mobx-react';
// import { useState } from 'react'
import FleetStore from '../../../../../../store/FleetStore/FleetStore';
import VehicleStore from '../../../../../../store/VehicleStore/VehicleStore';
import { RealtimeMap } from './components/Map/Map';
import { RealtimeVehicles } from './components/Vehicles/Vehicles';
import { VehicleModal } from './components/Modals/Vehicle';
import { RealtimeTable } from './components/Table/Table';

export const RealtimeVehiclesView = observer(function RealtimeVehiclesViewComponent() {
  return FleetStore.fleet.key ? (
    <div className="flex-1 relative z-0 flex overflow-hidden">
      <aside
        className={`${VehicleStore.hideVehicles ? 'hidden' : 'hidden relative xl:order-first xl:flex xl:flex-col flex-shrink-0 w-96 border-r border-gray-200'}`}>
        <RealtimeVehicles/>
      </aside>
      <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none xl:order-last">
        <div className="absolute inset-0 py-4 px-4 sm:px-4 lg:px-4">
          <div className="h-full border-2 relative border-gray-200 rounded-lg overflow-hidden">
            <div className="absolute left-0 top-0 z-10 p-2">
                            <span className="relative z-0 inline-flex shadow-sm rounded-md cursor-pointer bg-white">

                          <svg xmlns="http://www.w3.org/2000/svg"
                               onClick={() => VehicleStore.hideVehicleList(!VehicleStore.hideVehicles)}
                               className={`h-6 w-6 ${
                                 VehicleStore.hideVehicles ? 'hidden' : ''
                               }`} fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
  <path strokeLinecap="round" strokeLinejoin="round"
        d="M4 8V4m0 0h4M4 4l5 5m11-1V4m0 0h-4m4 0l-5 5M4 16v4m0 0h4m-4 0l5-5m11 5l-5-5m5 5v-4m0 4h-4"/>
</svg>

                              <svg xmlns="http://www.w3.org/2000/svg"
                                   onClick={() => VehicleStore.hideVehicleList(!VehicleStore.hideVehicles)}
                                   className={`h-5 w-5 ${
                                     VehicleStore.hideVehicles ? '' : 'hidden'
                                   }`} viewBox="0 0 20 20" fill="currentColor">
  <path
    d="M8 5a1 1 0 100 2h5.586l-1.293 1.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L13.586 5H8zM12 15a1 1 0 100-2H6.414l1.293-1.293a1 1 0 10-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L6.414 15H12z"/>
</svg>


                            </span>
            </div>
            <div className="absolute right-0 top-0 z-10 p-2 bg-white">
                            <span className="relative z-0 inline-flex shadow-sm rounded-md">
                                <button
                                  type="button"
                                  onClick={() => VehicleStore.setMapChecked(!VehicleStore.mapChecked)}
                                  className={`relative inline-flex items-center px-4 py-2 border text-sm font-medium border-blue-500 ${
                                    VehicleStore.mapChecked ? 'bg-blue-400 text-white' : 'bg-white text-gray-700'
                                  }`}>
                                    Map
                                </button>
                                <button
                                  type="button"
                                  onClick={() => VehicleStore.setMapChecked(!VehicleStore.mapChecked)}
                                  className={`-ml-px relative inline-flex items-center px-4 rounded-tr-lg py-2 border text-sm font-medium border-blue-500 ${
                                    !VehicleStore.mapChecked ? 'bg-blue-400 text-white' : 'bg-white text-gray-700'
                                  }`}>
                                    Table
                                </button>
                            </span>
            </div>
            {VehicleStore.mapChecked ? <RealtimeMap/> : <RealtimeTable/>}
          </div>
        </div>
      </main>

      {/*{mapChecked ?  <VehicleModal />: ''}*/}
      <VehicleModal/>
    </div>
  ) : (
    <div className="w-full h-full fixed block bottom-0 left-0 bg-gray-600 opacity-75 z-100">
      <svg className="animate-spin -ml-1 mr-3 h-20 w-20 text-white absolute top-1/2 left-1/2"
           xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"/>
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        />
      </svg>
    </div>
  );
});
