import { action, makeAutoObservable } from 'mobx'

export class NotificationStore {
    toastText: string = ''
    showToast: boolean = false
    successfulToast: boolean = true

    constructor() {
        makeAutoObservable(this)
    }

    @action setToast(text: string, toggle: boolean, type: boolean) {
        this.toastText = text
        this.showToast = toggle
        this.successfulToast = type

        setTimeout(() => {
            this.showToast = false
        }, 5000)
    }
}

export default new NotificationStore()
