import { observer } from 'mobx-react'
import FleetStore from '../../../../../../../../store/FleetStore/FleetStore'
import DutyStore from '../../../../../../../../store/DutyStore/DutyStore'
import { goHistory } from '../../../../../../../../index'

export const DutiesAside = observer(function DutiesAsideComponent() {
    return (
        FleetStore.vehicles && (
            <aside className="hidden relative xl:order-first xl:flex xl:flex-col flex-shrink-0 w-96 border-r border-gray-200">
                <nav className="h-full bg-white overflow-y-auto" aria-label="Directory">
                    <div className="relative">
                        <div className="z-10 sticky top-0 border-t border-b border-gray-200 bg-gray-50 px-6 py-3 text-sm font-medium text-black">
                            <h3 className="text-lg">Duties ({FleetStore.duties.active.length})</h3>
                        </div>
                        <ul role="list" className="relative z-0 divide-y divide-gray-200">
                            {FleetStore.duties.active.map((value, index) => {
                                return (
                                    <li key={index} onClick={() => DutyStore.selectDuty(value)}>
                                        <div
                                            className={`transition duration-500 ease-in-out block cursor-pointer hover:bg-gray-50 text-black hover:text-white hover:bg-green-400
                                                ${DutyStore.selectedDuty && DutyStore.selectedDuty === value ? 'bg-green-400 text-white' : 'bg-white'}`}>
                                            <div className="px-4 py-4 sm:px-6">
                                                <h3 className="text-sm font-medium">{value.vehicle.vehicle_number}</h3>
                                                <div className="flex items-center justify-between">
                                                    {value.ref !== '' ? <p className="text-sm">{value.ref}</p> : <p className="text-sm">Generic Trip</p>}
                                                    <p className="text-sm">{new Date(value.created_at).toLocaleString()}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                )
                            })}
                            <li onClick={() => goHistory.push(`/fleet/${FleetStore.fleet.slug}/operations/dispatch`)}>
                                <div className="transition duration-500 ease-in-out block cursor-pointer hover:bg-gray-50 text-black hover:text-white hover:bg-blue-700">
                                    <div className="px-4 py-4 sm:px-6">
                                        <div className="flex items-center justify-between">
                                            <p className="text-sm font-medium truncate">Go to Dispatch</p>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li onClick={() => goHistory.push(`/fleet/${FleetStore.fleet.slug}/reports/duties`)}>
                                <div className="transition duration-500 ease-in-out block cursor-pointer hover:bg-gray-50 text-black hover:text-white hover:bg-blue-700">
                                    <div className="px-4 py-4 sm:px-6">
                                        <div className="flex items-center justify-between">
                                            <p className="text-sm font-medium truncate">Go to Reports</p>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </nav>
            </aside>
        )
    )
})
