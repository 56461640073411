import { makeAutoObservable } from 'mobx'
import { UsersProps } from '../../interfaces'

export class UsersStore {
    users: UsersProps[] = []

    constructor() {
        makeAutoObservable(this)
    }

    updateUsers(users: UsersProps[]) {
        this.users = users
    }
}

export default new UsersStore()
