import { observer } from 'mobx-react'
import FleetStore from '../../../../../../../../store/FleetStore/FleetStore'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import TripsStore from '../../../../../../../../store/TripsStore/TripsStore'

export const PlanningTripsAside = observer(function PlanningTripsAsideComponent() {
    return (
        FleetStore.vehicles && (
            <aside
                className="hidden relative xl:order-first xl:flex xl:flex-col flex-shrink-0 w-96 border-r border-gray-200">
                <nav className="h-full bg-white overflow-y-auto" aria-label="Directory">
                    <div className="relative">
                        <div
                            className="z-10 sticky top-0 border-t border-b border-gray-200 bg-gray-50 px-6 py-3 text-sm font-medium text-black">
                            <h3 className="text-lg">Trips</h3>
                        </div>
                        <DragDropContext>
                            <Droppable droppableId="stops">

                                {(provided) => (
                                    <ul role="list" {...provided.droppableProps} ref={provided.innerRef}
                                        className="stops relative z-0 divide-y divide-gray-200">
                                        <li onClick={() => TripsStore.selectTrip({'schedule': [], 'name': ''})}>
                                            <div
                                                className="transition duration-500 ease-in-out block cursor-pointer hover:bg-gray-50 text-black hover:text-white hover:bg-green-400">
                                                <div className="px-4 py-4 sm:px-6">
                                                    <div className="flex items-center">
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6"
                                                             fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                                  strokeWidth={2}
                                                                  d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"/>
                                                        </svg>
                                                        <p className="text-sm font-medium truncate ml-2">
                                                            Create new trip
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>

                                        {FleetStore.trips.map((value, index) => {
                                            return (
                                                <Draggable key={value.key} draggableId={value.key} index={index}>
                                                    {(draggable) => (
                                                        <li ref={draggable.innerRef} {...draggable.draggableProps} {...draggable.dragHandleProps}
                                                            onClick={() => TripsStore.selectTrip(value)}>
                                                            <div
                                                                className="transition duration-500 ease-in-out block cursor-pointer hover:bg-gray-50 text-black hover:text-white hover:bg-green-400">
                                                                <div className="px-4 py-4 sm:px-6">
                                                                    <div className="flex items-center justify-between">
                                                                        <p className="text-sm font-medium truncate">
                                                                            {value.name}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    )}
                                                </Draggable>
                                            )
                                        })}
                                        {provided.placeholder}

                                        <li className="bg-gray-100 cursor-not-allowed">
                                            <div className="cursor-not-allowed">
                                                <div className="px-4 py-4 sm:px-6">
                                                    <div className="flex items-center justify-between">
                                                        <p className="text-sm font-medium truncate">
                                                            Pre-load Trips
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </div>
                </nav>
            </aside>
        )
    )
})
