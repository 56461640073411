import { action, makeAutoObservable } from 'mobx'
import { DutiesProps } from '../../interfaces'

export class DutyStore {
    duties: DutiesProps[] = []
    selectedDuty: any = null
    selectedInactiveDuty: any = null
    selectedDutyETA: any = null

    constructor() {
        makeAutoObservable(this)
    }

    @action updateDuties(duties: DutiesProps[]) {
        this.duties = duties
    }

    @action selectDuty(duty: any) {
        this.selectedDuty = duty
    }

    @action selectInactiveDuty(duty: any) {
        this.selectedInactiveDuty = duty
    }

    @action setSelectedDutyETA(selectedDutyETA: any) {
        this.selectedDutyETA = selectedDutyETA
    }
}

export default new DutyStore()
