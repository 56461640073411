import { action, makeAutoObservable } from 'mobx'
import { WaypointProps } from '../../interfaces'

export class WaypointStore {
    waypoints: WaypointProps[] = []
    selectedWaypoint: any = null
    create: boolean = false
    tempBatch: any = null

    constructor() {
        makeAutoObservable(this)
    }

    @action updateWaypoints(waypoints: WaypointProps[]) {
        this.waypoints = waypoints
    }

    @action selectWaypoint(waypoint: any) {
        this.selectedWaypoint = waypoint
    }

    @action select(waypoint: any) {
        this.selectedWaypoint = waypoint
    }

    updateSelectedWaypointLatitude(lat: number) {
        this.selectedWaypoint.latitude = lat
    }

    @action updateSelectedWaypointLongitude(lng: number) {
        this.selectedWaypoint.longitude = lng
    }

    @action updateSelectedWaypointName(name: string) {
        this.selectedWaypoint.name = name
    }

    @action updateSelectedWaypointOptionalID(optional_id: string) {
        this.selectedWaypoint.optional_id = optional_id
    }

    @action updateSelectedWaypointRadiusSize(size: number) {
        this.selectedWaypoint.radius_in_km = size
    }

    @action updateSelectedWaypointLocations(locations: any) {
        this.selectedWaypoint.locations = locations
    }

    @action updateSelectedWaypointArrivalEvent(arrive: boolean) {
        this.selectedWaypoint.events.arrived = arrive
    }

    @action updateSelectedWaypointDepartureEvent(depart: boolean) {
        this.selectedWaypoint.events.departed = depart
    }

    @action updateWaypoint(waypoint: any) {
        let selectedWaypointIndex = this.waypoints.findIndex(o => o.key == waypoint.key)
        this.waypoints[selectedWaypointIndex] = waypoint
        this.selectedWaypoint = waypoint
    }

    @action clearSelect() {
        this.selectedWaypoint = null
    }

    @action toggleCreate() {
        this.create = !this.create
    }

    @action populateBatch(features: any) {
        this.tempBatch = features
    }

    @action setBatchRadius(size: number) {
        for (const feature of this.tempBatch) {
            feature.radius_in_km = size
        }
    }
}

export default new WaypointStore()
