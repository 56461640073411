import { observer } from 'mobx-react'
import FleetStore from '../../../../../../../../store/FleetStore/FleetStore'
import VehicleStore from '../../../../../../../../store/VehicleStore/VehicleStore'
import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import Vehicle from '../../../../../../../../components/Vehicle'
import { Marker, StaticMap } from 'react-map-gl'
import { goHistory } from '../../../../../../../../index'
import DutyStore from '../../../../../../../../store/DutyStore/DutyStore'
import DashboardMapStore from '../../../../../../../../store/DashboardMapStore/DashboardMapStore'

export const VehicleModal = observer(function VehicleModalComponent() {
    const [open, setOpen] = useState(true)

    const handleDutySelect = () => {
        setOpen(false)
        if (VehicleStore.selectedVehicle.duty === null) {
            VehicleStore.selectForDispatch(VehicleStore.selectedVehicle)
            VehicleStore.clearSelect()
            goHistory.push(`/fleet/${FleetStore.fleet.slug}/operations/dispatch`)
        } else {
            DutyStore.selectDuty(FleetStore.duties.active.find(d => d.vehicle.key === VehicleStore.selectedVehicle.key))
            goHistory.push(`/fleet/${FleetStore.fleet.slug}/monitoring/duties`)
        }
        VehicleStore.clearSelect()
    }

    return (
        FleetStore.vehicles &&
        VehicleStore.selectedVehicle && (
            <Transition.Root show={!!VehicleStore.selectedVehicle || open} as={Fragment}>
                <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto " onClose={setOpen}>
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0">
                            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:p-6">
                                <div>
                                    <div className="flex items-center justify-between">
                                        <p className="text-sm font-medium text-black truncate">Vehicle details:</p>
                                        <div className="ml-2 flex-shrink-0 flex">
                                            <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                                {typeof VehicleStore.selectedVehicle.server_updated_at !== 'undefined' && VehicleStore.selectedVehicle.server_updated_at !== null
                                                    ? 'Last updated: ' + new Date(VehicleStore.selectedVehicle.server_updated_at).toLocaleString()
                                                    : 'Last updated: N/A'}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="mt-3 text-center sm:mt-5">
                                        <div className="mt-2 h-48 border rounded">
                                            <StaticMap
                                                mapboxApiAccessToken={'pk.eyJ1IjoiYWZyb2xhYnNyaWNoYXJkIiwiYSI6ImNrZndsM2NneDFtM3gzMnBkOXNmdWlmYjQifQ.hxu87TsIwxtUwlU6iX7Q-w'}
                                                width="100%"
                                                height="100%"
                                                latitude={VehicleStore.selectedVehicle.position.latitude}
                                                longitude={VehicleStore.selectedVehicle.position.longitude}
                                                zoom={16}
                                                className="full-w ">
                                                <Marker latitude={VehicleStore.selectedVehicle.position.latitude} longitude={VehicleStore.selectedVehicle.position.longitude}>
                                                    <Vehicle {...VehicleStore.selectedVehicle} />
                                                </Marker>
                                            </StaticMap>
                                        </div>
                                        <div className="mt-2">
                                            <div className="px-4 py-4 sm:px-6">
                                                <div className="mt-2 sm:flex sm:justify-between">
                                                    <div className="sm:flex">
                                                        <p className="flex items-center text-sm text-gray-500">
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                className="h-6 w-6 text-black"
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                                stroke="currentColor">
                                                                <path d="M9 17a2 2 0 11-4 0 2 2 0 014 0zM19 17a2 2 0 11-4 0 2 2 0 014 0z" />
                                                                <path
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                    strokeWidth={2}
                                                                    d="M13 16V6a1 1 0 00-1-1H4a1 1 0 00-1 1v10a1 1 0 001 1h1m8-1a1 1 0 01-1 1H9m4-1V8a1 1 0 011-1h2.586a1 1 0 01.707.293l3.414 3.414a1 1 0 01.293.707V16a1 1 0 01-1 1h-1m-6-1a1 1 0 001 1h1M5 17a2 2 0 104 0m-4 0a2 2 0 114 0m6 0a2 2 0 104 0m-4 0a2 2 0 114 0"
                                                                />
                                                            </svg>
                                                            {VehicleStore.selectedVehicle.registration}
                                                        </p>
                                                        <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6">
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                className="h-6 w-6 text-black"
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                                stroke="currentColor">
                                                                <path
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                    strokeWidth={2}
                                                                    d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"
                                                                />
                                                            </svg>
                                                            {VehicleStore.selectedVehicle.duty
                                                                ? VehicleStore.selectedVehicle.duty.ref === ''
                                                                    ? 'Generic Trip'
                                                                    : VehicleStore.selectedVehicle.duty.ref
                                                                : 'N/A'}
                                                        </p>
                                                        <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6">
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                className="h-6 w-6 text-black"
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                                stroke="currentColor">
                                                                <path
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                    strokeWidth={2}
                                                                    d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z"
                                                                />
                                                            </svg>
                                                            {VehicleStore.selectedVehicle.is_ignition_on ? 'On' : 'Off'}
                                                        </p>

                                                        <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6">
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                className="h-6 w-6 text-black"
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                                stroke="currentColor">
                                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8" />
                                                            </svg>
                                                            {VehicleStore.selectedVehicle.speed}KM/H
                                                        </p>
                                                        <button
                                                            className="mt-2 flex items-center text-sm text-black sm:mt-0 sm:ml-6"
                                                            onClick={() => {
                                                                VehicleStore.setMapChecked(true)
                                                                DashboardMapStore.updateViewport({
                                                                    latitude: VehicleStore.selectedVehicle.position.latitude,
                                                                    longitude: VehicleStore.selectedVehicle.position.longitude,
                                                                    zoom: 16,
                                                                })
                                                                VehicleStore.clearSelect()
                                                            }}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                <path
                                                                    stroke-linecap="round"
                                                                    stroke-linejoin="round"
                                                                    stroke-width="2"
                                                                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0zM10 7v3m0 0v3m0-3h3m-3 0H7"
                                                                />
                                                            </svg>
                                                            Zoom
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                                    <button
                                        type="button"
                                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                                        onClick={() => handleDutySelect()}>
                                        {VehicleStore.selectedVehicle.duty === null ? 'Dispatch' : 'View duty'}
                                    </button>
                                    <button
                                        type="button"
                                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                                        onClick={() => VehicleStore.clearSelect()}>
                                        Close
                                    </button>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
        )
    )
})
