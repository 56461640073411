import { observer } from 'mobx-react'
import FleetStore from '../../../../../../../../store/FleetStore/FleetStore'
import VehicleStore from '../../../../../../../../store/VehicleStore/VehicleStore'

export const RealtimeTable = observer(function RealtimeTableComponent() {
    return (
        FleetStore.vehicles && (
            <div className="absolute top-0 left-0 flex flex-col h-full w-full">
                <div className="-my-2 overflow-auto sm:-mx-6 lg:-ml-8 lg:-mr-12">
                    <div className="py-2 align-middle overflow-auto inline-block min-w-full sm:px-6 lg:px-8">
                        <div className="shadow overflow-auto border-b border-gray-200 sm:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-200">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Vehicle Identity
                                        </th>
                                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Registration
                                        </th>
                                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Fleet Number
                                        </th>
                                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            On Duty
                                        </th>
                                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Last Updated
                                        </th>
                                        <th scope="col" className="relative px-6 py-3">
                                            <span className="sr-only">Edit</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="overflow-y-scroll">
                                    {FleetStore.vehicles
                                        .slice()
                                        .sort((a, b) => a.vehicle_number.localeCompare(b.vehicle_number))
                                        .map((vehicle, idx) => (
                                            <tr
                                                key={vehicle.key}
                                                className={`transition duration-500 ease-in-out cursor-pointer hover:text-white hover:bg-green-400 ${
                                                    idx % 2 === 0 ? 'bg-white' : 'bg-gray-50'
                                                } `}
                                                onClick={() => {
                                                    VehicleStore.select(vehicle)
                                                }}>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{vehicle.vehicle_number}</td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{vehicle.registration}</td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{vehicle.fleet_number}</td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{vehicle.duty ? 'Yes' : 'No'}</td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{new Date(vehicle.server_updated_at).toLocaleString()}</td>
                                                <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium" />
                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        )
    )
})
